import React, { useState } from "react";

const BloodBanks = () => {
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [bloodBanks, setBloodBanks] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchBloodBanks = async () => {
    setLoading(true);
    setError(null);

    try {
      if (!location.latitude || !location.longitude) {
        setError("Please allow location access.");
        setLoading(false);
        return;
      }

      const response = await fetch(
        `https://api.eraktkosh.in/api/bloodbanks?latitude=${location.latitude}&longitude=${location.longitude}`
      );

      if (!response.ok) {
        throw new Error("Unable to fetch blood banks at this time.");
      }

      const data = await response.json();
      setBloodBanks(data.bloodBanks || []);
    } catch (err) {
      setError(err.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setError(null);
        },
        () => {
          setError("Location access denied.");
        }
      );
    } else {
      setError("Geolocation is not supported by your browser.");
    }
  };

  return (
    <div className="blood-bank-container">
      <h1 className="header">Find Nearby Blood Banks</h1>
      <p className="description">
        Locate the nearest blood banks using your current location. Click on
        the button below to get started.
      </p>

      <div className="action-buttons">
        <button className="submit-button" onClick={handleGetLocation}>
          Get Current Location
        </button>
        <button
          className="submit-button"
          onClick={fetchBloodBanks}
          disabled={!location.latitude || !location.longitude || loading}
        >
          {loading ? "Loading..." : "Find Blood Banks"}
        </button>
      </div>

      {error && <p className="error">{error}</p>}

      {bloodBanks.length > 0 && (
        <div className="blood-bank-list">
          {bloodBanks.map((bank, index) => (
            <div className="blood-bank-card" key={index}>
              <h2 className="bank-name">{bank.name}</h2>
              <p>
                <strong>Address:</strong> {bank.address}
              </p>
              <p>
                <strong>Contact:</strong> {bank.contact}
              </p>
              <p>
                <strong>Distance:</strong> {bank.distance} km
              </p>
            </div>
          ))}
        </div>
      )}

      {bloodBanks.length === 0 && !error && !loading && (
        <p className="no-results">No blood banks found in your area.</p>
      )}
    </div>
  );
};

export default BloodBanks;
