import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

const LungCancer = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const sections = {
    overviewRef: useRef(null),
    symptomsRef: useRef(null),
    causesRef: useRef(null),
    diagnosisRef: useRef(null),
    treatmentRef: useRef(null),
  };

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="Women-container">
      {/* Back Navigation */}
      <div className="Women-back-arrow" onClick={handleBackClick}>
        ← Back
      </div>

      {/* Sidebar */}
      <div className="Women-sidebar">
        <h3>Table of Contents</h3>
        <ul>
          <li onClick={() => scrollToSection(sections.overviewRef)}>Overview</li>
          <li onClick={() => scrollToSection(sections.symptomsRef)}>Symptoms</li>
          <li onClick={() => scrollToSection(sections.causesRef)}>Causes and Risk Factors</li>
          <li onClick={() => scrollToSection(sections.diagnosisRef)}>Diagnosis</li>
          <li onClick={() => scrollToSection(sections.treatmentRef)}>Treatment</li>
        </ul>
      </div>

      {/* Main Content */}
      <div className="Women-content">
        {/* Overview */}
        <div ref={sections.overviewRef} className="Women-menstrual-card">
          <h2>Overview</h2>
          <p>
            Lung cancer begins in the lungs and is often linked to smoking, though non-smokers can also develop it. It is one of the leading causes of cancer-related deaths worldwide.
          </p>
        </div>

        {/* Symptoms */}
        <div ref={sections.symptomsRef} className="Women-menstrual-card">
          <h3>Symptoms</h3>
          <ul>
            <li>Persistent cough, often with blood</li>
            <li>Shortness of breath</li>
            <li>Chest pain that worsens with deep breathing or coughing</li>
            <li>Hoarseness</li>
            <li>Unexplained weight loss</li>
            <li>Recurring respiratory infections, like pneumonia or bronchitis</li>
          </ul>
        </div>

        {/* Causes and Risk Factors */}
        <div ref={sections.causesRef} className="Women-menstrual-card">
          <h3>Causes and Risk Factors</h3>
          <ul>
            <li><strong>Smoking:</strong> The leading cause, accounting for 80–90% of cases</li>
            <li><strong>Secondhand Smoke:</strong> Increased risk for non-smokers</li>
            <li><strong>Exposure:</strong> Radon gas, asbestos, and air pollution</li>
            <li><strong>Genetics:</strong> Family history of lung cancer</li>
          </ul>
        </div>

        {/* Diagnosis */}
        <div ref={sections.diagnosisRef} className="Women-menstrual-card">
          <h3>Diagnosis</h3>
          <ul>
            <li><strong>Imaging Tests:</strong> Chest X-ray, CT scan, PET scan</li>
            <li><strong>Biopsy:</strong> Fine-needle aspiration or bronchoscopy</li>
            <li><strong>Sputum Cytology:</strong> Analyzing mucus for cancer cells</li>
          </ul>
        </div>

        {/* Treatment */}
        <div ref={sections.treatmentRef} className="Women-menstrual-card">
          <h3>Treatment</h3>
          <ul>
            <li><strong>Surgery:</strong> Lobectomy, pneumonectomy, or segmentectomy</li>
            <li><strong>Radiation Therapy:</strong> For localized cancer or symptom relief</li>
            <li><strong>Chemotherapy:</strong> Systemic drugs to kill cancer cells</li>
            <li><strong>Targeted Therapy:</strong> EGFR inhibitors or ALK inhibitors</li>
            <li><strong>Immunotherapy:</strong> Drugs like pembrolizumab to boost the immune system</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LungCancer;
