import React, { useState } from "react";
import "../styles/FAQ.css"; // Import the CSS file
import { FiPlus, FiMinus } from "react-icons/fi";

// FAQ Data
const faqData = [
  {
    question: "What is HSuite?",
    answer: "HSuite is a comprehensive healthcare management platform designed to enhance hospital operations and improve patient care through technology-driven solutions, including app integrations, AI-powered predictions, and seamless data management."
  },
  {
    question: "Is HSuite customizable for my hospital’s specific needs?",
    answer: "Yes, HSuite is fully customizable. We work closely with your team to tailor the platform to meet your hospital’s specific workflows, specialties, and operational needs."
  },
  {
    question: "How does HSuite ensure data security?",
    answer: "HSuite complies with global data security standards like HIPAA to ensure that all patient and hospital data is encrypted, securely stored, and accessed only by authorized personnel."
  },
  {
    question: "Does HSuite work offline?",
    answer: "Yes, HSuite offers an Offline Mode for uninterrupted access to critical features even without an internet connection. Once online, all data is synced automatically."
  },
  {
    question: "How can I get started with HSuite?",
    answer: "Getting started is easy! Simply contact us via the Contact section or sign up for a demo through the website. Our team will guide you through onboarding and setup."
  },
  {
    question: "Does HSuite offer 24/7 support?",
    answer: "Yes, we provide 24/7 customer support to ensure uninterrupted service. Whether it's a technical query or a feature request, our team is here to assist you."
  },
  {
    question: "Can HSuite integrate with our existing hospital systems?",
    answer: "Absolutely. HSuite is designed to integrate seamlessly with existing hospital management systems (HMS), ensuring minimal disruption during adoption."
  },
  {
    question: "What is the ‘Know Your Health’ feature?",
    answer: "The 'Know Your Health' section offers pre-diagnostic tools for users to understand their health better. It provides information on BMI, nutrition, women's health, mental health, child care, and more."
  },
  {
    question: "Is training provided for HSuite?",
    answer: "Yes, we offer comprehensive training sessions for your staff to ensure they are comfortable using HSuite’s features and tools."
  },
  {
    question: "Can HSuite handle multi-specialty hospitals?",
    answer: "Yes, HSuite is scalable and customizable for multi-specialty hospitals, providing tools to manage various departments efficiently under a single platform."
  },
  {
    question: "Does HSuite support multi-location hospitals?",
    answer: "Absolutely. HSuite is designed to manage operations across multiple hospital locations seamlessly, providing unified data access and centralized management."
  },
  {
    question: "How does HSuite improve the patient experience?",
    answer: "HSuite enhances patient experience by offering appointment scheduling and reminders, providing personalized health insights and progress tracking, and ensuring secure and fast communication with healthcare providers."
  },
  {
    question: "Does HSuite require IT expertise to set up?",
    answer: "No, HSuite is designed to be user-friendly. Our team provides complete onboarding support, and the platform is intuitive enough for non-technical staff to use efficiently."
  },
  {
    question: "How does HSuite handle updates?",
    answer: "HSuite automatically updates its software to ensure your hospital always has the latest features, security patches, and regulatory compliance updates."
  },
  {
    question: "How do I contact support if I encounter issues?",
    answer: "You can reach our support team through the Contact Us section on the website or directly via phone or email. We offer 24/7 support for any technical or operational issues."
  },
  {
    question: "Does HSuite offer insights and analytics?",
    answer: "Yes, HSuite provides detailed analytics, helping you track patient trends, hospital performance, and staff productivity. These insights enable data-driven decision-making."
  },
  {
    question: "Can I migrate my hospital’s existing data to HSuite?",
    answer: "Yes, HSuite provides seamless data migration services to transfer your existing records, ensuring no downtime or data loss during the process."
  },
  {
    question: "Is HSuite affordable for small clinics?",
    answer: "Yes, HSuite offers flexible pricing models suitable for clinics, small hospitals, and large healthcare organizations. We ensure affordability without compromising on quality."
  },
  {
    question: "How long does it take to implement HSuite?",
    answer: "Implementation timelines vary based on hospital size and requirements. However, our team ensures a swift and efficient setup process, typically completed in a few weeks."
  }
];

// FAQ Component
// FAQ Component
const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-section">
      <h2 className="faq-heading">Frequently Asked Questions</h2>
      <div className="faq-list">
        {faqData.map((item, index) => (
          <div
            key={index}
            className={`faq-item ${activeIndex === index ? "active" : ""}`}
          >
            <div onClick={() => toggleFAQ(index)} className="faq-question">
              <span>{item.question}</span>
              <span className="faq-question-icon">
                {activeIndex === index ? <FiMinus /> : <FiPlus />}
              </span>
            </div>
            <div className="faq-answer">{item.answer}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;