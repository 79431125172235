import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

const ChildVaccination = () => {
  const navigate = useNavigate();

  const sections = {
    birthToSixMonthsRef: useRef(null),
    sixToEighteenMonthsRef: useRef(null),
    twoToSixYearsRef: useRef(null),
    optionalVaccinesRef: useRef(null),
    keyNotesRef: useRef(null),
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="Women-container">
      <div className="Women-back-arrow" onClick={handleBackClick}>
        ← Back
      </div>
      <div className="Women-sidebar">
        <h3>Table of Contents</h3>
        <ul>
          <li onClick={() => scrollToSection(sections.birthToSixMonthsRef)}>Birth to 6 Months</li>
          <li onClick={() => scrollToSection(sections.sixToEighteenMonthsRef)}>6 to 18 Months</li>
          <li onClick={() => scrollToSection(sections.twoToSixYearsRef)}>2 to 6 Years</li>
          <li onClick={() => scrollToSection(sections.optionalVaccinesRef)}>Optional Vaccines</li>
          <li onClick={() => scrollToSection(sections.keyNotesRef)}>Key Notes</li>
        </ul>
      </div>
      <div className="Women-content">
        <div ref={sections.birthToSixMonthsRef} className="Women-menstrual-card">
          <h2>Birth to 6 Months</h2>
          <h3>At Birth</h3>
          <ul>
            <li>
              <strong>Hepatitis B (HepB) Vaccine:</strong> Dose 1 at birth.
            </li>
          </ul>
          <h3>1 to 2 Months</h3>
          <ul>
            <li>
              <strong>Hepatitis B (HepB) Vaccine:</strong> Dose 2 (1-2 months after the first dose).
            </li>
          </ul>
          <h3>2 Months</h3>
          <ul>
            <li>DTaP: Dose 1</li>
            <li>IPV: Dose 1</li>
            <li>Hib: Dose 1</li>
            <li>PCV: Dose 1</li>
            <li>Rotavirus: Dose 1</li>
          </ul>
          <h3>4 Months</h3>
          <ul>
            <li>DTaP: Dose 2</li>
            <li>IPV: Dose 2</li>
            <li>Hib: Dose 2</li>
            <li>PCV: Dose 2</li>
            <li>Rotavirus: Dose 2</li>
          </ul>
          <h3>6 Months</h3>
          <ul>
            <li>DTaP: Dose 3</li>
            <li>IPV: Dose 3</li>
            <li>Hib: Dose 3</li>
            <li>PCV: Dose 3</li>
            <li>Rotavirus: Dose 3</li>
            <li>HepB: Dose 3</li>
            <li>Influenza Vaccine (Optional): Annual after 6 months.</li>
          </ul>
        </div>
        <div ref={sections.sixToEighteenMonthsRef} className="Women-menstrual-card">
          <h2>6 Months to 18 Months</h2>
          <h3>6 to 12 Months</h3>
          <ul>
            <li>MMR: Dose 1 (9-12 months).</li>
          </ul>
          <h3>12 to 15 Months</h3>
          <ul>
            <li>Hib: Final dose</li>
            <li>PCV: Final dose</li>
            <li>MMR: Dose 1</li>
            <li>Varicella: Dose 1</li>
            <li>Hepatitis A: Dose 1 (12-23 months)</li>
          </ul>
          <h3>15 to 18 Months</h3>
          <ul>
            <li>DTaP: Dose 4</li>
          </ul>
        </div>
        <div ref={sections.twoToSixYearsRef} className="Women-menstrual-card">
          <h2>2 to 6 Years</h2>
          <h3>4 to 6 Years</h3>
          <ul>
            <li>DTaP: Final dose</li>
            <li>IPV: Final dose</li>
            <li>MMR: Dose 2</li>
            <li>Varicella: Dose 2</li>
          </ul>
          <h3>Influenza Vaccine</h3>
          <ul>
            <li>Annual flu shot recommended.</li>
          </ul>
          <h3>Other Vaccines (As Needed)</h3>
          <ul>
            <li>Hepatitis A: Dose 2 (if not already administered).</li>
          </ul>
        </div>
        <div ref={sections.optionalVaccinesRef} className="Women-menstrual-card">
          <h2>Optional Vaccines</h2>
          <ul>
            <li>BCG: Protects against tuberculosis (given at birth in high-risk areas).</li>
            <li>Meningococcal Vaccine: Protects against meningococcal disease.</li>
            <li>HPV: Administered from 9 years onward to prevent certain cancers.</li>
            <li>Typhoid Vaccine: Recommended in high-risk regions.</li>
          </ul>
        </div>
        <div ref={sections.keyNotesRef} className="Women-menstrual-card">
          <h2>Key Notes</h2>
          <ul>
            <li>The vaccine schedule may vary depending on your country or region.</li>
            <li>Consult your pediatrician for personalized vaccination plans.</li>
            <li>Ensure all doses are completed on time for maximum protection.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ChildVaccination;
