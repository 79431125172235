import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

const TuberculosisOverview = () => {
  const navigate = useNavigate();

  const sections = {
    typesRef: useRef(null),
    causesRef: useRef(null),
    symptomsRef: useRef(null),
    riskFactorsRef: useRef(null),
    diagnosisRef: useRef(null),
    treatmentRef: useRef(null),
    preventionRef: useRef(null),
    complicationsRef: useRef(null),
    globalImpactRef: useRef(null),
    livingRef: useRef(null),
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="Women-container">
      <div className="Women-back-arrow" onClick={handleBackClick}>
        ← Back
      </div>
      <div className="Women-sidebar">
        <h3>Table of Contents</h3>
        <ul>
          <li onClick={() => scrollToSection(sections.typesRef)}>Types of Tuberculosis</li>
          <li onClick={() => scrollToSection(sections.causesRef)}>Causes</li>
          <li onClick={() => scrollToSection(sections.symptomsRef)}>Symptoms</li>
          <li onClick={() => scrollToSection(sections.riskFactorsRef)}>Risk Factors</li>
          <li onClick={() => scrollToSection(sections.diagnosisRef)}>Diagnosis</li>
          <li onClick={() => scrollToSection(sections.treatmentRef)}>Treatment</li>
          <li onClick={() => scrollToSection(sections.preventionRef)}>Prevention</li>
          <li onClick={() => scrollToSection(sections.complicationsRef)}>Complications</li>
          <li onClick={() => scrollToSection(sections.globalImpactRef)}>Global Impact</li>
          <li onClick={() => scrollToSection(sections.livingRef)}>Living with TB</li>
        </ul>
      </div>
      <div className="Women-content">
        <div ref={sections.typesRef} className="Women-menstrual-card">
          <h2>Types of Tuberculosis</h2>
          <ul>
            <li><strong>Pulmonary Tuberculosis:</strong> Affects the lungs, the most common form.</li>
            <li><strong>Extrapulmonary Tuberculosis:</strong> Affects other organs like bones or kidneys.</li>
            <li><strong>Latent TB Infection:</strong> Dormant bacteria causing no symptoms, non-contagious.</li>
            <li><strong>Active Tuberculosis:</strong> Contagious, bacteria cause symptoms.</li>
          </ul>
        </div>

        <div ref={sections.causesRef} className="Women-menstrual-card">
          <h2>Causes of Tuberculosis</h2>
          <p>TB is caused by the bacterium <i>Mycobacterium tuberculosis</i>, spreading through:</p>
          <ul>
            <li><strong>Airborne Transmission:</strong> Inhalation of droplets from an infected person’s cough or sneeze.</li>
            <li>Close, prolonged contact increases risk.</li>
          </ul>
        </div>

        <div ref={sections.symptomsRef} className="Women-menstrual-card">
          <h2>Symptoms of Tuberculosis</h2>
          <h4>Pulmonary TB Symptoms:</h4>
          <ul>
            <li>Persistent cough (3+ weeks).</li>
            <li>Coughing up blood or mucus.</li>
            <li>Chest pain, fever, night sweats, and fatigue.</li>
            <li>Unexplained weight loss and loss of appetite.</li>
          </ul>
          <h4>Extrapulmonary TB Symptoms:</h4>
          <ul>
            <li>Swollen lymph nodes, joint pain, or neurological symptoms (e.g., seizures).</li>
            <li>Blood in urine (renal TB).</li>
          </ul>
        </div>

        <div ref={sections.riskFactorsRef} className="Women-menstrual-card">
          <h2>Risk Factors</h2>
          <ul>
            <li><strong>Weakened Immune System:</strong> HIV/AIDS, cancer, or diabetes.</li>
            <li><strong>Malnutrition:</strong> Weakens the ability to fight infections.</li>
            <li><strong>Poor Living Conditions:</strong> Overcrowded or poorly ventilated spaces.</li>
            <li><strong>Substance Abuse:</strong> Alcohol or drug use.</li>
            <li><strong>Healthcare Workers:</strong> Increased exposure to TB patients.</li>
          </ul>
        </div>

        <div ref={sections.diagnosisRef} className="Women-menstrual-card">
          <h2>Diagnosis of Tuberculosis</h2>
          <ul>
            <li><strong>Skin Test:</strong> Injecting tuberculin under the skin to check for a reaction.</li>
            <li><strong>Blood Test:</strong> Detects latent TB infections.</li>
            <li><strong>Chest X-ray:</strong> Identifies lung abnormalities caused by TB.</li>
            <li><strong>Sputum Test:</strong> Tests mucus for TB bacteria.</li>
            <li>Other tests like CT scans or biopsies for extrapulmonary TB.</li>
          </ul>
        </div>

        <div ref={sections.treatmentRef} className="Women-menstrual-card">
          <h2>Treatment of Tuberculosis</h2>
          <ul>
            <li><strong>First-Line Medications:</strong> Rifampicin, Isoniazid, Ethambutol, and Pyrazinamide (6-9 months).</li>
            <li><strong>Drug-Resistant TB:</strong> MDR-TB and XDR-TB require longer and more complex treatments.</li>
            <li><strong>Supportive Care:</strong> Adequate nutrition, rest, and monitoring for side effects.</li>
          </ul>
        </div>

        <div ref={sections.preventionRef} className="Women-menstrual-card">
          <h2>Prevention of Tuberculosis</h2>
          <ul>
            <li><strong>BCG Vaccine:</strong> Protects against severe TB in children.</li>
            <li><strong>Infection Control:</strong> Isolate patients with active TB and use PPE in high-risk settings.</li>
            <li><strong>Healthy Living:</strong> Strengthen the immune system with good nutrition and habits.</li>
          </ul>
        </div>

        <div ref={sections.complicationsRef} className="Women-menstrual-card">
          <h2>Complications of Tuberculosis</h2>
          <ul>
            <li><strong>Lung Damage:</strong> Permanent scarring and reduced lung function.</li>
            <li><strong>Miliary TB:</strong> Bacteria spreading to multiple organs.</li>
            <li><strong>Pleural Effusion:</strong> Fluid buildup around the lungs.</li>
            <li><strong>Spinal TB:</strong> Leads to deformity or paralysis.</li>
            <li><strong>TB Meningitis:</strong> Severe neurological damage.</li>
          </ul>
        </div>

        <div ref={sections.globalImpactRef} className="Women-menstrual-card">
          <h2>Global Impact of Tuberculosis</h2>
          <p>TB is one of the leading infectious causes of death worldwide:</p>
          <ul>
            <li><strong>High-Burden Countries:</strong> India, China, and South Africa have the highest cases.</li>
            <li><strong>HIV and TB:</strong> HIV increases the risk of TB by 18 times.</li>
          </ul>
        </div>

        <div ref={sections.livingRef} className="Women-menstrual-card">
          <h2>Living with Tuberculosis</h2>
          <ul>
            <li><strong>Adherence to Treatment:</strong> Complete the prescribed course to prevent drug resistance.</li>
            <li><strong>Community Support:</strong> Support groups can help deal with stigma and psychological challenges.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TuberculosisOverview;
