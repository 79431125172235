import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

const PneumoniaOverview = () => {
  const navigate = useNavigate();

  const sections = {
    causesRef: useRef(null),
    typesRef: useRef(null),
    symptomsRef: useRef(null),
    diagnosisRef: useRef(null),
    treatmentRef: useRef(null),
    complicationsRef: useRef(null),
    preventionRef: useRef(null),
    whenToSeekRef: useRef(null),
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="Women-container">
      <div className="Women-back-arrow" onClick={handleBackClick}>
        ← Back
      </div>
      <div className="Women-sidebar">
        <h3>Table of Contents</h3>
        <ul>
          <li onClick={() => scrollToSection(sections.causesRef)}>Causes of Pneumonia</li>
          <li onClick={() => scrollToSection(sections.typesRef)}>Types of Pneumonia</li>
          <li onClick={() => scrollToSection(sections.symptomsRef)}>Symptoms of Pneumonia</li>
          <li onClick={() => scrollToSection(sections.diagnosisRef)}>Diagnosis of Pneumonia</li>
          <li onClick={() => scrollToSection(sections.treatmentRef)}>Treatment of Pneumonia</li>
          <li onClick={() => scrollToSection(sections.complicationsRef)}>Complications of Pneumonia</li>
          <li onClick={() => scrollToSection(sections.preventionRef)}>Prevention of Pneumonia</li>
          <li onClick={() => scrollToSection(sections.whenToSeekRef)}>When to Seek Medical Help</li>
        </ul>
      </div>
      <div className="Women-content">
        <div ref={sections.causesRef} className="Women-menstrual-card">
          <h2>Causes of Pneumonia</h2>
          <ul>
            <li>
              <strong>Bacteria:</strong> Commonly caused by *Streptococcus pneumoniae*, among others.
            </li>
            <li>
              <strong>Viruses:</strong> Influenza, RSV, coronaviruses, and more.
            </li>
            <li>
              <strong>Fungi:</strong> Found in individuals with weakened immune systems.
            </li>
            <li>
              <strong>Aspiration Pneumonia:</strong> Inhalation of food or liquids into the lungs.
            </li>
          </ul>
        </div>

        <div ref={sections.typesRef} className="Women-menstrual-card">
          <h2>Types of Pneumonia</h2>
          <ul>
            <li><strong>Community-Acquired Pneumonia (CAP):</strong> Develops outside healthcare settings.</li>
            <li><strong>Hospital-Acquired Pneumonia (HAP):</strong> Acquired during a hospital stay.</li>
            <li><strong>Ventilator-Associated Pneumonia (VAP):</strong> A subset of HAP.</li>
            <li><strong>Aspiration Pneumonia:</strong> Caused by foreign material entering the lungs.</li>
            <li><strong>Atypical Pneumonia:</strong> Often referred to as "walking pneumonia."</li>
          </ul>
        </div>

        <div ref={sections.symptomsRef} className="Women-menstrual-card">
          <h2>Symptoms of Pneumonia</h2>
          <ul>
            <li>High fever and chills.</li>
            <li>Persistent cough producing mucus.</li>
            <li>Shortness of breath or difficulty breathing.</li>
            <li>Chest pain, especially when coughing or breathing.</li>
            <li>Fatigue, weakness, and loss of appetite.</li>
          </ul>
        </div>

        <div ref={sections.diagnosisRef} className="Women-menstrual-card">
          <h2>Diagnosis of Pneumonia</h2>
          <ul>
            <li>
              <strong>Medical History and Physical Exam:</strong> Identifying symptoms and abnormal lung sounds.
            </li>
            <li>
              <strong>Imaging:</strong> Chest X-rays or CT scans to detect lung inflammation.
            </li>
            <li>
              <strong>Laboratory Tests:</strong> Blood tests, sputum analysis, and pulse oximetry.
            </li>
          </ul>
        </div>

        <div ref={sections.treatmentRef} className="Women-menstrual-card">
          <h2>Treatment of Pneumonia</h2>
          <ul>
            <li><strong>Bacterial Pneumonia:</strong> Treated with antibiotics.</li>
            <li><strong>Viral Pneumonia:</strong> Managed with antivirals and supportive care.</li>
            <li><strong>Fungal Pneumonia:</strong> Treated with antifungal medications.</li>
            <li><strong>Supportive Treatments:</strong> Oxygen therapy, pain relievers, and hydration.</li>
          </ul>
        </div>

        <div ref={sections.complicationsRef} className="Women-menstrual-card">
          <h2>Complications of Pneumonia</h2>
          <ul>
            <li><strong>Pleural Effusion:</strong> Fluid buildup in the chest.</li>
            <li><strong>Sepsis:</strong> Life-threatening infection spreading to other organs.</li>
            <li><strong>Respiratory Failure:</strong> Inability of the lungs to supply enough oxygen.</li>
          </ul>
        </div>

        <div ref={sections.preventionRef} className="Women-menstrual-card">
          <h2>Prevention of Pneumonia</h2>
          <ul>
            <li><strong>Vaccination:</strong> Pneumococcal, flu, and COVID-19 vaccines.</li>
            <li><strong>Good Hygiene:</strong> Wash hands and avoid touching your face.</li>
            <li><strong>Healthy Lifestyle:</strong> Exercise, balanced diet, and enough sleep.</li>
          </ul>
        </div>

        <div ref={sections.whenToSeekRef} className="Women-menstrual-card">
          <h2>When to Seek Medical Help</h2>
          <ul>
            <li>Difficulty breathing or persistent high fever.</li>
            <li>Severe chest pain or confusion.</li>
            <li>Bluish lips or skin indicating oxygen deficiency.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PneumoniaOverview;
