import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

const PancreaticCancer = () => {
  const navigate = useNavigate();

  const sections = {
    overviewRef: useRef(null),
    symptomsRef: useRef(null),
    causesRef: useRef(null),
    diagnosisRef: useRef(null),
    treatmentRef: useRef(null),
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="Women-container">
      <div className="Women-back-arrow" onClick={handleBackClick}>
        ← Back
      </div>
      <div className="Women-sidebar">
        <h3>Table of Contents</h3>
        <ul>
          <li onClick={() => scrollToSection(sections.overviewRef)}>Overview</li>
          <li onClick={() => scrollToSection(sections.symptomsRef)}>Symptoms</li>
          <li onClick={() => scrollToSection(sections.causesRef)}>Causes and Risk Factors</li>
          <li onClick={() => scrollToSection(sections.diagnosisRef)}>Diagnosis</li>
          <li onClick={() => scrollToSection(sections.treatmentRef)}>Treatment</li>
        </ul>
      </div>
      <div className="Women-content">
        <div ref={sections.overviewRef} className="Women-menstrual-card">
          <h2>Overview</h2>
          <p>
            Pancreatic cancer occurs in the pancreas, an organ that produces insulin and digestive enzymes. It is often diagnosed late, making it one of the more aggressive types of cancer.
          </p>
        </div>
        <div ref={sections.symptomsRef} className="Women-menstrual-card">
          <h3>Symptoms</h3>
          <ul>
            <li>Persistent abdominal or back pain</li>
            <li>Loss of appetite and unintentional weight loss</li>
            <li>Jaundice (yellowing of the skin and eyes)</li>
            <li>Light-colored stools or dark urine</li>
            <li>Nausea and vomiting</li>
            <li>New onset of diabetes or poorly controlled diabetes</li>
            <li>Fatigue</li>
          </ul>
        </div>
        <div ref={sections.causesRef} className="Women-menstrual-card">
          <h3>Causes and Risk Factors</h3>
          <ul>
            <li>Smoking: Significantly increases risk.</li>
            <li>Chronic pancreatitis: Long-term inflammation of the pancreas.</li>
            <li>Diabetes: Particularly type 2 diabetes.</li>
            <li>Family history: Genetic predisposition or hereditary cancer syndromes.</li>
            <li>Obesity: Linked to increased pancreatic cancer risk.</li>
            <li>Age: Most common in those over 60.</li>
          </ul>
        </div>
        <div ref={sections.diagnosisRef} className="Women-menstrual-card">
          <h3>Diagnosis</h3>
          <ul>
            <li>Imaging tests: CT scan, MRI, or endoscopic ultrasound (EUS).</li>
            <li>Biopsy: Fine-needle aspiration to confirm cancer.</li>
            <li>Blood tests: Checking levels of CA 19-9, a tumor marker.</li>
          </ul>
        </div>
        <div ref={sections.treatmentRef} className="Women-menstrual-card">
          <h3>Treatment</h3>
          <ul>
            <li>Surgery: Whipple procedure, distal pancreatectomy, or total pancreatectomy.</li>
            <li>Radiation therapy: External beam radiation to shrink tumors.</li>
            <li>Chemotherapy: Drugs like gemcitabine or FOLFIRINOX for advanced stages.</li>
            <li>Targeted therapy: Drugs for tumors with specific mutations (e.g., PARP inhibitors for BRCA mutations).</li>
            <li>Immunotherapy: For tumors with specific genetic markers.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PancreaticCancer;
