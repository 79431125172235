import React from "react";
import "../styles/BuiltForYourHospital.css";
import leftImage from "../assets/mock.png"; // Replace with actual path to your left image
import rightImage from "../assets/mock.png"; // Replace with actual path to your right image
import { FiArrowUpRight } from "react-icons/fi";
import { useNavigate } from 'react-router-dom';

function BuiltForYourHospital() {
  const navigate = useNavigate(); // Initialize the navigate function

  const handleViewMore = () => {
    navigate("/products"); // Navigate to the /products page
  };

  return (
    <section className="built-for-hospital">
      <h2 className="heading">Built for Your Hospital’s Unique Needs</h2>
      <div className="images-container">
        <div className="image-wrapper">
          <img
            src={leftImage}
            alt="Hospital Dashboard 1"
            className="hospital-image"
          />
          <p className="image-caption">Receptionist Dashboard</p>
        </div>
        <div className="image-wrapper">
          <img
            src={rightImage}
            alt="Hospital Dashboard 2"
            className="hospital-image"
          />
          <p className="image-caption">Doctor Dashboard</p>
        </div>
      </div>
      <button className="view-more-button" onClick={handleViewMore}>
        View More <FiArrowUpRight className="arrow-icon" />
      </button>
      <p className="sub-text">See How HSuite Fits Your Needs</p>
    </section>
  );
}

export default BuiltForYourHospital;
