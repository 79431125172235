import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import { ReactComponent as CancerIcon } from './icons/cancer.svg';

function CancerSymptoms() {
    const navigate = useNavigate();  // useNavigate hook to handle navigation

    const handleBackClick = () => {
        navigate(-1);  // This will navigate back to the previous page
    };

    return (
        <div className="kyh-container">
            <div className="back-arrow" onClick={handleBackClick}>
                ← KYH
            </div>
        <h1 className='kyh-heading'>Cancer Types</h1>
        <div className="kyh-row1">
        <div className="kyh-card">
            <Link to="/kyh/breast-cancer">
            <CancerIcon className="kyh-image" />
            <h3>Breast Cancer</h3>
            </Link>
        </div>
        <div className="kyh-card">
            <Link to="/kyh/lung-cancer">
            <CancerIcon className="kyh-image" />
            <h3>Lung Cancer</h3>
            </Link>
        </div>
        <div className="kyh-card">
            <Link to="/kyh/colorectal-cancer">
            <CancerIcon className="kyh-image" />
            <h3>Colorectal Cancer</h3>
            </Link>
        </div>
        </div>
        <div className="kyh-row2">
        <div className="kyh-card">
            <Link to="/kyh/prostate-cancer">
            <CancerIcon className="kyh-image" />
            <h3>Prostate Cancer</h3>
            </Link>
        </div>
        <div className="kyh-card">
            <Link to="/kyh/skin-cancer">
            <CancerIcon className="kyh-image" />
            <h3>Skin Cancer</h3>
            </Link>
        </div>
        <div className="kyh-card">
            <Link to="/kyh/stomack-cancer">
            <CancerIcon className="kyh-image" />
            <h3>Stomach Cancer</h3>
            </Link>
        </div>
        </div>
        <div className="kyh-row3">
        <div className="kyh-card">
            <Link to="/kyh/liver-cancer">
            <CancerIcon className="kyh-image" />
            <h3>Liver Cancer</h3>
            </Link>
        </div>
        <div className="kyh-card">
            <Link to="/kyh/pancreatic-cancer">
            <CancerIcon className="kyh-image" />
            <h3>Pancreatic Cancer</h3>
            </Link>
        </div>
        <div className="kyh-card">
            <Link to="/kyh/pancreatic-cancer">
            <CancerIcon className="kyh-image" />
            <h3>Bladder Cancer</h3>
            </Link>
        </div>
        </div>
    </div>
    );
}

export default CancerSymptoms