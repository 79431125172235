import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

const Pregnancy = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const introductionRef = useRef(null);
  const firstTrimesterRef = useRef(null);
  const secondTrimesterRef = useRef(null);
  const thirdTrimesterRef = useRef(null);
  const prenatalandPostnatalRef = useRef(null);
  const maternalHealthRef = useRef(null);
  const whatShouldntRef = useRef(null);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="Women-container">
      {/* Back Navigation */}
      <div className="Women-back-arrow" onClick={handleBackClick}>
        ← Women Health
      </div>

      {/* Sidebar */}
      <div className="Women-sidebar">
        <h3>Table of Contents</h3>
        <ul>
          <li onClick={() => scrollToSection(introductionRef)}>
            Introduction to Pregnancy and Maternity
          </li>
          <li onClick={() => scrollToSection(firstTrimesterRef)}>
            First Trimester (Weeks 1-12)
          </li>
          <li onClick={() => scrollToSection(secondTrimesterRef)}>
            Second Trimester (Weeks 13-26)
          </li>
          <li onClick={() => scrollToSection(thirdTrimesterRef)}>
            Third Trimester (Weeks 27-40)
          </li>
          <li onClick={() => scrollToSection(prenatalandPostnatalRef)}>
            Prenatal and Postnatal Care
          </li>
          <li onClick={() => scrollToSection(maternalHealthRef)}>
            Maternal Health
          </li>
          <li onClick={() => scrollToSection(whatShouldntRef)}>
          What Shouldn’t Be Consumed or Done During Pregnancy
          </li>
        </ul>
      </div>

      {/* Main Content */}
      <div className="Women-content">
        {/* Introduction to Pregnancy */}
        <div ref={introductionRef} className="Women-menstrual-card">
          <h2>Introduction to Pregnancy and Maternity</h2>
          <p>
            Pregnancy is a transformative journey that brings significant physical, emotional, and psychological changes to expectant mothers.
          </p>
          <p>
            Understanding the basics of pregnancy and maternity care can help you navigate this life-changing experience with confidence and knowledge.
          </p>
          <h4>What is Pregnancy?</h4>
          <p>
            Pregnancy is the period during which a fertilized egg develops into a baby inside a woman's uterus. Typically lasting around 40 weeks, it is divided into three trimesters.
          </p>
          <h4>Key Facts about Pregnancy:</h4>
          <ul>
            <li>Pregnancy begins with fertilization, when a sperm cell merges with an egg cell.</li>
            <li>The developing baby is referred to as an embryo during the first eight weeks and a fetus thereafter.</li>
            <li>Hormonal changes support the development of the fetus and prepare the mother for childbirth.</li>
          </ul>
        </div>

        {/* First Trimester */}
        <div ref={firstTrimesterRef} className="Women-menstrual-card">
          <h3>First Trimester (Weeks 1-12)</h3>
          <h4>Overview</h4>
          <p>
            The first trimester begins on the first day of your last menstrual period and lasts until the end of week 12.
          </p>
          <h4>Key Developments:</h4>
          <ul>
            <li>Fertilization and Implantation: The fertilized egg implants itself in the uterus.</li>
            <li>Organ Formation: Basic structures like the brain, spinal cord, and limbs begin to develop.</li>
            <li>Placenta Development: The placenta forms to provide nutrients and oxygen.</li>
          </ul>
          <h4>Common Symptoms:</h4>
          <ul>
            <li>Nausea and Vomiting</li>
            <li>Fatigue</li>
            <li>Breast Changes</li>
          </ul>
        </div>

        {/* Second Trimester */}
        <div ref={secondTrimesterRef} className="Women-menstrual-card">
          <h3>Second Trimester (Weeks 13-26)</h3>
          <h4>Overview</h4>
          <p>
            The second trimester is often considered the most comfortable phase of pregnancy.
          </p>
          <h4>Key Developments:</h4>
          <ul>
            <li>Fetal Growth: The baby grows significantly and begins to move.</li>
            <li>Organ Maturation: Major organs continue to develop and mature.</li>
            <li>Gender Identification: Ultrasounds may determine the baby's sex.</li>
          </ul>
        </div>

        {/* Third Trimester */}
        <div ref={thirdTrimesterRef} className="Women-menstrual-card">
          <h3>Third Trimester (Weeks 27-40)</h3>
          <h4>Overview</h4>
          <p>
            The third trimester is characterized by rapid growth and preparation for labor.
          </p>
          <h4>Key Developments:</h4>
          <ul>
            <li>Weight Gain: The baby gains weight quickly.</li>
            <li>Lung Development: The baby practices breathing movements.</li>
            <li>Positioning for Birth: The baby often turns to a head-down position.</li>
          </ul>
        </div>


        {/* Prenatal and Postnatal Care */}
<div className="Women-menstrual-card">
  <h3>Prenatal and Postnatal Care</h3>
  <p>
    Prenatal and postnatal care are crucial components of a healthy pregnancy and the well-being of both mother and baby.
  </p>
  
  {/* Prenatal Care */}
  <div>
    <h4>Prenatal Care</h4>
    <p>
      Prenatal care refers to the medical attention and guidance received during pregnancy. Regular check-ups and screenings are essential for monitoring the health of the mother and the developing fetus.
    </p>
    <h5>Importance of Prenatal Care:</h5>
    <ul>
      <li>Early Detection of Complications</li>
      <li>Monitoring Fetal Development</li>
      <li>Education and Support</li>
    </ul>
    <h5>Components of Prenatal Care:</h5>
    <ul>
      <li>Initial Assessment</li>
      <li>Routine Check-Ups</li>
      <li>Ultrasounds and Screenings</li>
    </ul>
    <h5>Common Prenatal Tests:</h5>
    <ul>
      <li>Blood Tests</li>
      <li>Glucose Screening</li>
      <li>Group B Strep Test</li>
    </ul>
    <h5>Prenatal Nutrition:</h5>
    <ul>
      <li>Balanced Diet</li>
      <li>Prenatal Vitamins</li>
    </ul>
    <h5>Exercise and Physical Activity:</h5>
    <ul>
      <li>Safe Activities</li>
      <li>Avoid High-Risk Activities</li>
    </ul>
  </div>

  {/* Postnatal Care */}
  <div>
    <h4>Postnatal Care</h4>
    <p>
      Postnatal care refers to the support and medical attention provided to mothers and newborns after delivery. This period is critical for recovery, bonding, and establishing healthy habits.
    </p>
    <h5>Importance of Postnatal Care:</h5>
    <ul>
      <li>Physical Recovery</li>
      <li>Mental Health Support</li>
      <li>Feeding and Nutrition</li>
    </ul>
    <h5>Components of Postnatal Care:</h5>
    <ul>
      <li>Follow-Up Appointments</li>
      <li>Newborn Care</li>
      <li>Breastfeeding Support</li>
    </ul>
    <h5>Mental Health Considerations:</h5>
    <ul>
      <li>Recognizing Symptoms</li>
      <li>Seeking Help</li>
    </ul>
    <h5>Physical Recovery Tips:</h5>
    <ul>
      <li>Rest and Recovery</li>
      <li>Pelvic Floor Exercises</li>
    </ul>
  </div>
</div>

{/* Maternal Health */}
<div className="Women-menstrual-card">
  <h3>Maternal Health</h3>
  <p>
    Maternal health encompasses the physical, emotional, and social well-being of women during pregnancy, childbirth, and the postpartum period.
  </p>
  
  {/* Common Health Concerns */}
  <div>
    <h4>Common Health Concerns During Pregnancy</h4>
    <h5>Emotional and Mental Health Considerations:</h5>
    <ul>
      <li>Mood Swings</li>
      <li>Anxiety and Worry</li>
      <li>Anticipation and Excitement</li>
    </ul>
    <h5>Self-Care Strategies:</h5>
    <ul>
      <li>Connect with Others</li>
      <li>Practice Mindfulness</li>
      <li>Seek Professional Support</li>
    </ul>
    <h5>Physical Health and Wellness During Pregnancy:</h5>
    <ul>
      <li>Balanced Diet</li>
      <li>Hydration</li>
      <li>Exercise</li>
      <li>Sleep</li>
    </ul>
    <h5>Common Conditions:</h5>
    <ul>
      <li>Gestational Diabetes</li>
      <li>Hypertension and Preeclampsia</li>
      <li>Anemia</li>
    </ul>
  </div>

  {/* Lifestyle Factors */}
  <div>
    <h4>Lifestyle Factors Impacting Maternal Health</h4>
    <h5>Nutrition:</h5>
    <ul>
      <li>Balanced Diet</li>
      <li>Hydration</li>
      <li>Nutritional Supplements</li>
    </ul>
    <h5>Physical Activity:</h5>
    <ul>
      <li>Benefits of Exercise</li>
      <li>Recommended Activities</li>
    </ul>
    <h5>Avoiding Harmful Substances:</h5>
    <ul>
      <li>Substance Use</li>
      <li>Medication Awareness</li>
    </ul>
    <h5>Stress Management:</h5>
    <ul>
      <li>Coping Strategies</li>
      <li>Support Systems</li>
    </ul>
  </div>

  {/* Resources */}
  <div>
    <h4>Resources for Maternal Health</h4>
    <h5>Healthcare Providers:</h5>
    <ul>
      <li>Regular Check-Ups</li>
      <li>Lactation Consultants</li>
    </ul>
    <h5>Support Groups:</h5>
    <ul>
      <li>Community Resources</li>
      <li>Online Communities</li>
    </ul>
    <h5>Educational Resources:</h5>
    <ul>
      <li>Books and Websites</li>
      <li>Classes and Workshops</li>
    </ul>
  </div>
</div>

{/* What Shouldn’t Be Consumed or Done During Pregnancy */}
<div className="Women-menstrual-card">
  <h3>What Shouldn’t Be Consumed or Done During Pregnancy</h3>
  <p>
    Ensuring the health and safety of both the mother and developing fetus is paramount during pregnancy. Certain foods, substances, and activities should be avoided to minimize risks and complications.
  </p>

  {/* Foods and Beverages to Avoid */}
  <div>
    <h4>Foods and Beverages to Avoid</h4>
    <ul>
      <li>
        <strong>Raw or Undercooked Foods:</strong>
        <ul>
          <li><strong>Raw Fish:</strong> Avoid sushi, sashimi, and other dishes made with raw fish, which can harbor parasites or bacteria.</li>
          <li><strong>Undercooked Meat and Poultry:</strong> Ensure all meats are cooked to safe internal temperatures to prevent foodborne illnesses like salmonella and listeria.</li>
          <li><strong>Raw Eggs:</strong> Avoid foods containing raw or partially cooked eggs, such as homemade mayonnaise or certain dressings, to reduce the risk of salmonella.</li>
        </ul>
      </li>
      <li>
        <strong>Certain Seafood:</strong>
        <ul>
          <li><strong>High-Mercury Fish:</strong> Limit or avoid fish with high mercury levels, such as shark, swordfish, king mackerel, and tilefish. Opt for safer choices like salmon, shrimp, and catfish instead.</li>
        </ul>
      </li>
      <li>
        <strong>Unpasteurized Dairy Products:</strong>
        <ul>
          <li><strong>Soft Cheeses and Milk:</strong> Avoid unpasteurized dairy products, which can contain harmful bacteria. Stick to pasteurized options to ensure safety.</li>
        </ul>
      </li>
      <li>
        <strong>Caffeine:</strong>
        <ul>
          <li><strong>Limit Intake:</strong> High caffeine consumption may increase the risk of miscarriage or low birth weight. Limit caffeine to about 200 mg per day (about one 12-ounce cup of coffee).</li>
        </ul>
      </li>
      <li>
        <strong>Alcohol:</strong>
        <ul>
          <li><strong>Complete Abstinence:</strong> No amount of alcohol is considered safe during pregnancy. It can lead to fetal alcohol spectrum disorders (FASDs) and other developmental issues.</li>
        </ul>
      </li>
    </ul>
  </div>

  {/* Substances to Avoid */}
  <div>
    <h4>Substances to Avoid</h4>
    <ul>
      <li>
        <strong>Tobacco Products:</strong>
        <ul>
          <li><strong>Smoking:</strong> Smoking during pregnancy increases the risk of preterm birth, low birth weight, and complications. It’s essential to quit smoking and avoid secondhand smoke.</li>
        </ul>
      </li>
      <li>
        <strong>Recreational Drugs:</strong>
        <ul>
          <li><strong>Illicit Substances:</strong> Drugs such as cocaine, marijuana, and heroin can harm fetal development and lead to serious health issues for both mother and baby.</li>
        </ul>
      </li>
      <li>
        <strong>Certain Medications:</strong>
        <ul>
          <li><strong>Over-the-Counter and Prescription Drugs:</strong> Some medications may be unsafe during pregnancy. Always consult a healthcare provider before taking any medications, including herbal supplements and vitamins.</li>
        </ul>
      </li>
    </ul>
  </div>

  {/* Activities to Avoid */}
  <div>
    <h4>Activities to Avoid</h4>
    <ul>
      <li>
        <strong>High-Risk Activities:</strong>
        <ul>
          <li><strong>Contact Sports:</strong> Avoid sports with a high risk of injury, such as football, basketball, and soccer.</li>
          <li><strong>High-Impact Exercise:</strong> Steer clear of high-impact activities that may lead to falls or injury.</li>
        </ul>
      </li>
      <li>
        <strong>Hot Tubs and Saunas:</strong>
        <ul>
          <li><strong>Heat Exposure:</strong> High temperatures can be harmful during pregnancy. Avoid hot tubs, saunas, and excessively hot baths, especially in the first trimester, to reduce the risk of neural tube defects.</li>
        </ul>
      </li>
      <li>
        <strong>Long-Distance Travel:</strong>
        <ul>
          <li><strong>Travel Considerations:</strong> While many women travel safely during pregnancy, it’s best to avoid long-distance travel in the third trimester and consult with a healthcare provider regarding any travel plans.</li>
        </ul>
      </li>
      <li>
        <strong>Stressful Situations:</strong>
        <ul>
          <li><strong>Stress Management:</strong> High levels of stress can negatively impact both maternal and fetal health. Practice relaxation techniques and avoid excessively stressful situations.</li>
        </ul>
      </li>
    </ul>
  </div>
</div>


      </div>
    </div>
  );
};

export default Pregnancy;
