import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

const FirstAidGuide = () => {
  const navigate = useNavigate();

  const sections = {
    whatIsFirstAidRef: useRef(null),
    principlesRef: useRef(null),
    firstAidKitRef: useRef(null),
    proceduresRef: useRef(null),
    preparednessRef: useRef(null),
    callHelpRef: useRef(null),
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="Women-container">
      <div className="Women-back-arrow" onClick={handleBackClick}>
        ← Back
      </div>
      <div className="Women-sidebar">
        <h3>Table of Contents</h3>
        <ul>
          <li onClick={() => scrollToSection(sections.whatIsFirstAidRef)}>
            What is First Aid?
          </li>
          <li onClick={() => scrollToSection(sections.principlesRef)}>
            Key Principles of First Aid
          </li>
          <li onClick={() => scrollToSection(sections.firstAidKitRef)}>
            Basic First Aid Kit
          </li>
          <li onClick={() => scrollToSection(sections.proceduresRef)}>
            Essential First Aid Procedures
          </li>
          <li onClick={() => scrollToSection(sections.preparednessRef)}>
            Emergency Preparedness
          </li>
          <li onClick={() => scrollToSection(sections.callHelpRef)}>
            When to Call Emergency Services
          </li>
        </ul>
      </div>
      <div className="Women-content">
        <div ref={sections.whatIsFirstAidRef} className="Women-menstrual-card">
          <h2>What is First Aid?</h2>
          <p>
            First aid is the immediate and temporary care provided to a person
            suffering from injury or illness to:
          </p>
          <ul>
            <li>Preserve life.</li>
            <li>Prevent the condition from worsening.</li>
            <li>Promote recovery.</li>
          </ul>
        </div>

        <div ref={sections.principlesRef} className="Women-menstrual-card">
          <h2>Key Principles of First Aid</h2>
          <ul>
            <li>
              <strong>Assess the Situation:</strong> Ensure the area is safe for
              both the rescuer and the victim.
            </li>
            <li>
              <strong>Remain Calm:</strong> A calm demeanor helps reassure the
              injured person and allows better decision-making.
            </li>
            <li>
              <strong>Call for Help:</strong> Dial emergency services or seek
              medical assistance when necessary.
            </li>
            <li>
              <strong>Provide Care:</strong> Focus on stabilizing the condition
              while waiting for professional help.
            </li>
          </ul>
        </div>

        <div ref={sections.firstAidKitRef} className="Women-menstrual-card">
          <h2>Basic First Aid Kit</h2>
          <p>
            Every household, workplace, and vehicle should have a first aid kit
            containing:
          </p>
          <ul>
            <li>Adhesive bandages (various sizes)</li>
            <li>Sterile gauze pads and medical tape</li>
            <li>Antiseptic wipes or solution</li>
            <li>Antibiotic ointment</li>
            <li>Scissors and tweezers</li>
            <li>Thermometer</li>
            <li>Disposable gloves</li>
            <li>Pain relievers (e.g., paracetamol, ibuprofen)</li>
            <li>CPR face shield</li>
            <li>Emergency blanket</li>
            <li>First aid manual</li>
          </ul>
        </div>

        <div ref={sections.proceduresRef} className="Women-menstrual-card">
          <h2>Essential First Aid Procedures</h2>
          <p>
            Below are some common first aid procedures for various emergencies:
          </p>
          <ul>
            <li>
              <strong>CPR:</strong> Perform chest compressions if a person is
              unresponsive and not breathing.
            </li>
            <li>
              <strong>Controlling Bleeding:</strong> Apply pressure and elevate
              the area to stop the bleeding.
            </li>
            <li>
              <strong>Treating Burns:</strong> Cool the burn with running water
              and cover with a sterile dressing.
            </li>
            <li>
              <strong>Fractures and Sprains:</strong> Immobilize the area and
              apply ice to reduce swelling.
            </li>
            <li>
              <strong>Choking:</strong> Perform the Heimlich maneuver or give
              back blows, depending on the person's age.
            </li>
            <li>
              <strong>Shock:</strong> Lay the person flat, elevate their legs,
              and keep them warm.
            </li>
            <li>
              <strong>Poisoning:</strong> Call the poison control center and
              avoid inducing vomiting unless instructed.
            </li>
          </ul>
        </div>

        <div ref={sections.preparednessRef} className="Women-menstrual-card">
          <h2>Emergency Preparedness</h2>
          <ul>
            <li>
              <strong>Emergency Kit:</strong> Include water, non-perishable
              food, flashlight, radio, medications, and important documents.
            </li>
            <li>
              <strong>Develop a Plan:</strong> Create evacuation routes and
              practice emergency drills with family members.
            </li>
            <li>
              <strong>Be Trained:</strong> Take courses in first aid and CPR to
              be prepared for emergencies.
            </li>
          </ul>
        </div>

        <div ref={sections.callHelpRef} className="Women-menstrual-card">
          <h2>When to Call Emergency Services</h2>
          <p>Call for help in the following situations:</p>
          <ul>
            <li>Severe injuries, uncontrolled bleeding, or fractures.</li>
            <li>Breathing difficulties or choking.</li>
            <li>Loss of consciousness or seizures.</li>
            <li>Signs of heart attack or stroke.</li>
            <li>Suspected poisoning or drug overdose.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FirstAidGuide;
