import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

const ColorectalCancer = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const sections = {
    overviewRef: useRef(null),
    symptomsRef: useRef(null),
    causesRef: useRef(null),
    diagnosisRef: useRef(null),
    treatmentRef: useRef(null),
  };

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="Women-container">
      {/* Back Navigation */}
      <div className="Women-back-arrow" onClick={handleBackClick}>
        ← Back
      </div>

      {/* Sidebar */}
      <div className="Women-sidebar">
        <h3>Table of Contents</h3>
        <ul>
          <li onClick={() => scrollToSection(sections.overviewRef)}>Overview</li>
          <li onClick={() => scrollToSection(sections.symptomsRef)}>Symptoms</li>
          <li onClick={() => scrollToSection(sections.causesRef)}>Causes and Risk Factors</li>
          <li onClick={() => scrollToSection(sections.diagnosisRef)}>Diagnosis</li>
          <li onClick={() => scrollToSection(sections.treatmentRef)}>Treatment</li>
        </ul>
      </div>

      {/* Main Content */}
      <div className="Women-content">
        {/* Overview */}
        <div ref={sections.overviewRef} className="Women-menstrual-card">
          <h2>Overview</h2>
          <p>
            Colorectal cancer affects the colon or rectum and typically develops from precancerous polyps. It is common in older adults but can occur at any age.
          </p>
        </div>

        {/* Symptoms */}
        <div ref={sections.symptomsRef} className="Women-menstrual-card">
          <h3>Symptoms</h3>
          <ul>
            <li>Changes in bowel habits (diarrhea or constipation)</li>
            <li>Blood in stool or rectal bleeding</li>
            <li>Abdominal discomfort (cramping or pain)</li>
            <li>Fatigue and weakness</li>
            <li>Unexplained weight loss</li>
            <li>Feeling of incomplete bowel movement</li>
          </ul>
        </div>

        {/* Causes and Risk Factors */}
        <div ref={sections.causesRef} className="Women-menstrual-card">
          <h3>Causes and Risk Factors</h3>
          <ul>
            <li><strong>Diet:</strong> High in red or processed meat, low in fiber</li>
            <li><strong>Lifestyle:</strong> Lack of physical activity, smoking, obesity</li>
            <li><strong>Age:</strong> Risk increases after age 50</li>
            <li><strong>Family History:</strong> Genetic predisposition (e.g., Lynch syndrome)</li>
            <li><strong>Chronic Conditions:</strong> Inflammatory bowel disease (IBD), Crohn’s disease</li>
          </ul>
        </div>

        {/* Diagnosis */}
        <div ref={sections.diagnosisRef} className="Women-menstrual-card">
          <h3>Diagnosis</h3>
          <ul>
            <li><strong>Colonoscopy:</strong> Examining the colon with a camera</li>
            <li><strong>Fecal Occult Blood Test (FOBT):</strong> Detecting hidden blood in stool</li>
            <li><strong>CT Colonography:</strong> Imaging to check for polyps or tumors</li>
            <li><strong>Biopsy:</strong> Testing tissue samples for cancer cells</li>
          </ul>
        </div>

        {/* Treatment */}
        <div ref={sections.treatmentRef} className="Women-menstrual-card">
          <h3>Treatment</h3>
          <ul>
            <li><strong>Surgery:</strong> Polypectomy (removal of polyps) or colectomy</li>
            <li><strong>Radiation Therapy:</strong> For localized tumors</li>
            <li><strong>Chemotherapy:</strong> To shrink tumors or prevent recurrence</li>
            <li><strong>Targeted Therapy:</strong> Drugs targeting specific cancer cells</li>
            <li><strong>Immunotherapy:</strong> For advanced cases with specific biomarkers</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ColorectalCancer;
