import React from "react";
import "../styles/WhyHSuite.css";
import { AiOutlineCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";

function WhyHSuite() {
  return (
    <section className="why-hsuite">
      <h2 className="why-hsuite-heading">Why HSuite?</h2>
      <div className="comparison-container">
        <div className="column current-problems">
          <h3 className="why-hsuite-headings">Current Problems</h3>
          <ul>
            <li>
              <AiOutlineCloseCircle className="icon cross" />
              Lack of customization at granular level
            </li>
            <li>
              <AiOutlineCloseCircle className="icon cross" />
              Focus only on administrative tasks.
            </li>
            <li>
              <AiOutlineCloseCircle className="icon cross" />
              High cost for customisation
            </li>
          </ul>
        </div>
        <div className="column hsuite-solutions">
          <h3 className="why-hsuite-headings">HSuite Solutions</h3>
          <ul>
            <li>
              <AiOutlineCheckCircle className="icon check" />
              Fully Customised
            </li>
            <li>
              <AiOutlineCheckCircle className="icon check" />
              Unified clinical + administrative tools.
            </li>
            <li>
              <AiOutlineCheckCircle className="icon check" />
              Customization at affordable prices
            </li>
          </ul>
        </div>
      </div>
      <p className="footer-text">
        Built with hospitals in mind, designed to empower your team.
      </p>
    </section>
  );
}

export default WhyHSuite;
