import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

const LiverCancer = () => {
  const navigate = useNavigate();

  const sections = {
    overviewRef: useRef(null),
    symptomsRef: useRef(null),
    causesRef: useRef(null),
    diagnosisRef: useRef(null),
    treatmentRef: useRef(null),
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="Women-container">
      <div className="Women-back-arrow" onClick={handleBackClick}>
        ← Back
      </div>
      <div className="Women-sidebar">
        <h3>Table of Contents</h3>
        <ul>
          <li onClick={() => scrollToSection(sections.overviewRef)}>Overview</li>
          <li onClick={() => scrollToSection(sections.symptomsRef)}>Symptoms</li>
          <li onClick={() => scrollToSection(sections.causesRef)}>Causes and Risk Factors</li>
          <li onClick={() => scrollToSection(sections.diagnosisRef)}>Diagnosis</li>
          <li onClick={() => scrollToSection(sections.treatmentRef)}>Treatment</li>
        </ul>
      </div>
      <div className="Women-content">
        <div ref={sections.overviewRef} className="Women-menstrual-card">
          <h2>Overview</h2>
          <p>
            Liver cancer begins in liver cells, commonly hepatocellular carcinoma (HCC). It often arises due to chronic liver diseases like hepatitis or cirrhosis.
          </p>
        </div>
        <div ref={sections.symptomsRef} className="Women-menstrual-card">
          <h3>Symptoms</h3>
          <ul>
            <li>Unexplained weight loss</li>
            <li>Loss of appetite</li>
            <li>Upper abdominal pain or swelling</li>
            <li>Jaundice (yellowing of skin and eyes)</li>
            <li>Nausea and vomiting</li>
            <li>Fatigue and weakness</li>
            <li>White, chalky stools</li>
          </ul>
        </div>
        <div ref={sections.causesRef} className="Women-menstrual-card">
          <h3>Causes and Risk Factors</h3>
          <ul>
            <li>Chronic hepatitis infections (HBV, HCV)</li>
            <li>Cirrhosis</li>
            <li>Excessive alcohol consumption</li>
            <li>Non-alcoholic fatty liver disease (NAFLD)</li>
            <li>Aflatoxin exposure</li>
            <li>Hemochromatosis</li>
          </ul>
        </div>
        <div ref={sections.diagnosisRef} className="Women-menstrual-card">
          <h3>Diagnosis</h3>
          <ul>
            <li>Blood tests (AFP levels)</li>
            <li>Imaging tests (Ultrasound, CT, MRI)</li>
            <li>Biopsy</li>
          </ul>
        </div>
        <div ref={sections.treatmentRef} className="Women-menstrual-card">
          <h3>Treatment</h3>
          <ul>
            <li>Partial hepatectomy or liver transplant</li>
            <li>Ablation therapy</li>
            <li>Radiation therapy</li>
            <li>Targeted therapy (sorafenib, lenvatinib)</li>
            <li>Immunotherapy (atezolizumab)</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LiverCancer;
