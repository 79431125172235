import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import "./MentalHealthAssessment.css"

const MentalHealthAssessment = () => {
  const [answers, setAnswers] = useState(Array(10).fill(null));
  const [result, setResult] = useState(null);

  const navigate = useNavigate(); 
  const handleBackClick = () => {
    navigate(-1);  // This will navigate back to the previous page
  };

  const questions = [
    "In the last month, how often have you been upset because of something that happened unexpectedly?",
    "In the last month, how often have you felt that you were unable to control the important things in your life?",
    "In the last month, how often have you felt nervous and “stressed”?",
    "In the last month, how often have you felt confident about your ability to handle your personal problems?",
    "In the last month, how often have you felt that things were going your way?",
    "In the last month, how often have you found that you could not cope with all the things that you had to do?",
    "In the last month, how often have you been able to control irritations in your life?",
    "In the last month, how often have you felt that you were on top of things?",
    "In the last month, how often have you been angered because of things that were outside of your control?",
    "In the last month, how often have you felt difficulties were piling up so high that you could not overcome them?",
  ];

  const reverseScoredQuestions = [3, 4, 6, 7]; // Indexes of reverse scored questions

  const handleAnswerChange = (index, value) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index] = value;
    setAnswers(updatedAnswers);
  };

  const calculateResult = () => {
    let score = answers.reduce((total, answer, index) => {
      if (answer === null) return total;
      if (reverseScoredQuestions.includes(index)) {
        return total + (4 - answer); // Reverse scoring
      }
      return total + answer;
    }, 0);

    let stressLevel = "";
    if (score <= 13) stressLevel = "Low Stress";
    else if (score <= 26) stressLevel = "Moderate Stress";
    else stressLevel = "High Stress";

    setResult({ score, stressLevel });
  };

  return (
    <div className="mental-health-container">
        <div className="back-arrow" onClick={handleBackClick}>
                ← KYH
            </div>
      <h1 className="header">Perceived Stress Scale (PSS) Questionnaire</h1>
      <p className="description">
        The questions below ask about your feelings and thoughts during the last month. For each
        question, choose one of the following options:
      </p>
      <ul className="options">
        <li>0 = Never</li>
        <li>1 = Almost Never</li>
        <li>2 = Sometimes</li>
        <li>3 = Fairly Often</li>
        <li>4 = Very Often</li>
      </ul>

      <div className="questionnaire">
        {questions.map((question, index) => (
          <div className="question-card" key={index}>
            <p className="question">{`${index + 1}. ${question}`}</p>
            <div className="answers">
              {[0, 1, 2, 3, 4].map((option) => (
                <button
                  key={option}
                  className={`answer-button ${
                    answers[index] === option ? "selected" : ""
                  }`}
                  onClick={() => handleAnswerChange(index, option)}
                >
                  {option}
                </button>
              ))}
            </div>
          </div>
        ))}
      </div>

      <button
        className="submit-button"
        onClick={calculateResult}
        disabled={answers.includes(null)}
      >
        Submit
      </button>

      {result && (
        <div className="result-card">
          <h2>Your Results</h2>
          <p>Total Score: <span className="score">{result.score}</span></p>
          <p>Stress Level: <span className="stress-level">{result.stressLevel}</span></p>
        </div>
      )}
    </div>
  );
};

export default MentalHealthAssessment;
