import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

const BloodDonorCompatibility = () => {
  const navigate = useNavigate();

  const sections = {
    overviewRef: useRef(null),
    chartRef: useRef(null),
    guidelinesRef: useRef(null),
    importanceRef: useRef(null),
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="Women-container">
      <div className="Women-back-arrow" onClick={handleBackClick}>
        ← Back
      </div>
      <div className="Women-sidebar">
        <h3>Table of Contents</h3>
        <ul>
          <li onClick={() => scrollToSection(sections.overviewRef)}>Overview</li>
          <li onClick={() => scrollToSection(sections.chartRef)}>Compatibility Chart</li>
          <li onClick={() => scrollToSection(sections.guidelinesRef)}>Donation Guidelines</li>
          <li onClick={() => scrollToSection(sections.importanceRef)}>Importance</li>
        </ul>
      </div>
      <div className="Women-content">
        <div ref={sections.overviewRef} className="Women-menstrual-card">
          <h2>Blood Donor Compatibility Chart</h2>
          <p>
            A blood donor compatibility chart is a tool used to determine which blood types are compatible for donation and transfusion. This ensures the safety and effectiveness of blood transfusions by matching donors and recipients with compatible blood types.
          </p>
        </div>

        <div ref={sections.chartRef} className="Women-menstrual-card">
          <h2>Blood Type Compatibility Chart</h2>
          <p>Below is a compatibility chart for blood donors and recipients:</p>
          <table className="Women-table">
            <thead>
              <tr>
                <th>Recipient Blood Type</th>
                <th>Compatible Donor Blood Types</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>O-</td>
                <td>O-</td>
              </tr>
              <tr>
                <td>O+</td>
                <td>O-, O+</td>
              </tr>
              <tr>
                <td>A-</td>
                <td>A-, O-</td>
              </tr>
              <tr>
                <td>A+</td>
                <td>A+, A-, O+, O-</td>
              </tr>
              <tr>
                <td>B-</td>
                <td>B-, O-</td>
              </tr>
              <tr>
                <td>B+</td>
                <td>B+, B-, O+, O-</td>
              </tr>
              <tr>
                <td>AB-</td>
                <td>AB-, A-, B-, O-</td>
              </tr>
              <tr>
                <td>AB+</td>
                <td>AB+, AB-, A+, A-, B+, B-, O+, O-</td>
              </tr>
            </tbody>
          </table>
          <p>
            <strong>Universal Donor:</strong> O- (can donate to all blood types). <br />
            <strong>Universal Recipient:</strong> AB+ (can receive from all blood types).
          </p>
        </div>

        <div ref={sections.guidelinesRef} className="Women-menstrual-card">
          <h2>Blood Donation Guidelines</h2>
          <ul>
            <li>
              <strong>Who Can Donate?</strong>  
              <ul>
                <li>Be in good health.</li>
                <li>Be between 18 and 65 years old.</li>
                <li>Weigh at least 50 kg (110 lbs).</li>
                <li>Have a hemoglobin level of at least 12.5 g/dL.</li>
              </ul>
            </li>
            <li>
              <strong>How Often Can You Donate?</strong>
              <ul>
                <li>Whole Blood: Every 12 weeks (men), every 16 weeks (women).</li>
                <li>Platelets: Every 2 weeks (up to 24 times a year).</li>
                <li>Plasma: Every 4 weeks.</li>
              </ul>
            </li>
          </ul>
        </div>

        <div ref={sections.importanceRef} className="Women-menstrual-card">
          <h2>Importance of Blood Donor Compatibility</h2>
          <ul>
            <li>Prevents immune system reactions in recipients.</li>
            <li>Ensures the donated blood functions properly in the recipient's body.</li>
            <li>Minimizes the risk of complications like hemolysis (destruction of red blood cells).</li>
          </ul>
          <p>
            Regular blood donations, especially from universal donors (O-), are crucial to maintaining a steady blood supply.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BloodDonorCompatibility;
