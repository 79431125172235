import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

const NutritionGuide = () => {
  const navigate = useNavigate();

  const sections = {
    nutritionRef: useRef(null),
    nutrientsRef: useRef(null),
    balancedDietRef: useRef(null),
    dietaryRef: useRef(null),
    specialDietsRef: useRef(null),
    challengesRef: useRef(null),
    diseasePreventionRef: useRef(null),
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="Women-container">
      <div className="Women-back-arrow" onClick={handleBackClick}>
        ← Back
      </div>
      <div className="Women-sidebar">
        <h3>Table of Contents</h3>
        <ul>
          <li onClick={() => scrollToSection(sections.nutritionRef)}>What is Nutrition?</li>
          <li onClick={() => scrollToSection(sections.nutrientsRef)}>Key Nutrients and Their Functions</li>
          <li onClick={() => scrollToSection(sections.balancedDietRef)}>What is a Balanced Diet?</li>
          <li onClick={() => scrollToSection(sections.dietaryRef)}>Dietary Recommendations</li>
          <li onClick={() => scrollToSection(sections.specialDietsRef)}>Special Diets and Their Benefits</li>
          <li onClick={() => scrollToSection(sections.challengesRef)}>Nutritional Challenges and Solutions</li>
          <li onClick={() => scrollToSection(sections.diseasePreventionRef)}>Role of Nutrition in Disease Prevention</li>
        </ul>
      </div>
      <div className="Women-content">
        <div ref={sections.nutritionRef} className="Women-menstrual-card">
          <h2>What is Nutrition?</h2>
          <p>
            Nutrition is the process by which the body takes in and utilizes food to maintain health, growth, and energy. It includes digestion, absorption, and the metabolism of nutrients to sustain the body’s vital functions.
          </p>
        </div>

        <div ref={sections.nutrientsRef} className="Women-menstrual-card">
          <h2>Key Nutrients and Their Functions</h2>
          <ul>
            <li>
              <strong>Carbohydrates:</strong> Primary source of energy. Sources include whole grains, fruits, and vegetables.
            </li>
            <li>
              <strong>Proteins:</strong> Builds and repairs tissues. Found in lean meats, beans, and soy products.
            </li>
            <li>
              <strong>Fats:</strong> Provides energy and supports cell structure. Healthy fats include nuts, seeds, and olive oil.
            </li>
            <li>
              <strong>Vitamins:</strong> Regulates body processes. Examples include vitamin C for immunity and vitamin D for bones.
            </li>
            <li>
              <strong>Minerals:</strong> Maintains bone strength and nerve function. Examples include calcium and iron.
            </li>
            <li>
              <strong>Water:</strong> Hydrates the body and regulates temperature. Sources include water and hydrating fruits.
            </li>
          </ul>
        </div>

        <div ref={sections.balancedDietRef} className="Women-menstrual-card">
          <h2>What is a Balanced Diet?</h2>
          <p>
            A balanced diet provides the body with all the nutrients it needs in the right proportions. It includes a variety of foods from fruits, vegetables, whole grains, proteins, dairy or alternatives, and healthy fats.
          </p>
        </div>

        <div ref={sections.dietaryRef} className="Women-menstrual-card">
          <h2>Dietary Recommendations</h2>
          <ul>
            <li>Carbohydrates: 45-65% of daily calorie intake.</li>
            <li>Proteins: 10-35% of daily calorie intake.</li>
            <li>Fats: 20-35% of daily calorie intake.</li>
            <li>Limit added sugars and salt.</li>
            <li>Stay hydrated with 8-10 glasses of water daily.</li>
          </ul>
        </div>

        <div ref={sections.specialDietsRef} className="Women-menstrual-card">
          <h2>Special Diets and Their Benefits</h2>
          <ul>
            <li>
              <strong>Mediterranean Diet:</strong> Improves heart health with fruits, vegetables, and olive oil.
            </li>
            <li>
              <strong>Vegetarian or Vegan Diet:</strong> Promotes heart health by eliminating animal products.
            </li>
            <li>
              <strong>Keto Diet:</strong> High fat, low carb diet for weight loss.
            </li>
            <li>
              <strong>DASH Diet:</strong> Lowers blood pressure by reducing sodium intake.
            </li>
          </ul>
        </div>

        <div ref={sections.challengesRef} className="Women-menstrual-card">
          <h2>Nutritional Challenges and Solutions</h2>
          <ul>
            <li>
              <strong>Nutrient Deficiencies:</strong> Address with fortified foods and supplements.
            </li>
            <li>
              <strong>Overnutrition:</strong> Practice portion control to prevent obesity.
            </li>
            <li>
              <strong>Food Allergies:</strong> Use substitutes like almond milk for lactose intolerance.
            </li>
            <li>
              <strong>Picky Eating:</strong> Gradually introduce a variety of foods.
            </li>
          </ul>
        </div>

        <div ref={sections.diseasePreventionRef} className="Women-menstrual-card">
          <h2>Role of Nutrition in Disease Prevention</h2>
          <ul>
            <li>Reduce saturated fats and sodium for heart health.</li>
            <li>Choose low-glycemic foods for diabetes management.</li>
            <li>Eat antioxidant-rich foods to reduce cancer risk.</li>
            <li>Include calcium and vitamin D for strong bones.</li>
            <li>Consume probiotics and prebiotics for gut health.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NutritionGuide;
