import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

const BrainGuide = () => {
  const navigate = useNavigate();

  const sections = {
    structureRef: useRef(null),
    functionsRef: useRef(null),
    disordersRef: useRef(null),
    tipsRef: useRef(null),
    signsRef: useRef(null),
    emergenciesRef: useRef(null),
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="Women-container">
      <div className="Women-back-arrow" onClick={handleBackClick}>
        ← Back
      </div>
      <div className="Women-sidebar">
        <h3>Table of Contents</h3>
        <ul>
          <li onClick={() => scrollToSection(sections.structureRef)}>Structure of the Brain</li>
          <li onClick={() => scrollToSection(sections.functionsRef)}>Functions of the Brain</li>
          <li onClick={() => scrollToSection(sections.disordersRef)}>Common Brain Disorders</li>
          <li onClick={() => scrollToSection(sections.tipsRef)}>Tips for Maintaining Brain Health</li>
          <li onClick={() => scrollToSection(sections.signsRef)}>Signs of Brain-Related Issues</li>
          <li onClick={() => scrollToSection(sections.emergenciesRef)}>Emergencies in Brain Health</li>
        </ul>
      </div>
      <div className="Women-content">
        <div ref={sections.structureRef} className="Women-menstrual-card">
          <h2>Structure of the Brain</h2>
          <ul>
            <li>
              <strong>Cerebrum:</strong> Responsible for higher cognitive functions like memory and emotions.
              <ul>
                <li>Frontal Lobe: Controls decision-making and motor skills.</li>
                <li>Parietal Lobe: Processes sensory information.</li>
                <li>Occipital Lobe: Manages visual processing.</li>
                <li>Temporal Lobe: Handles auditory information and memory.</li>
              </ul>
            </li>
            <li>
              <strong>Cerebellum:</strong> Coordinates balance, posture, and fine motor movements.
            </li>
            <li>
              <strong>Brainstem:</strong> Regulates involuntary functions like breathing and heart rate.
            </li>
            <li>
              <strong>Limbic System:</strong> Manages emotions and long-term memory.
            </li>
            <li>
              <strong>Corpus Callosum:</strong> Connects the left and right hemispheres for communication.
            </li>
          </ul>
        </div>

        <div ref={sections.functionsRef} className="Women-menstrual-card">
          <h2>Functions of the Brain</h2>
          <ul>
            <li>
              <strong>Cognitive Functions:</strong> Thinking, reasoning, problem-solving, and decision-making.
            </li>
            <li>
              <strong>Sensory Processing:</strong> Interpreting signals from the five senses.
            </li>
            <li>
              <strong>Motor Control:</strong> Coordinating voluntary and involuntary movements.
            </li>
            <li>
              <strong>Emotions and Behavior:</strong> Regulating mood, emotions, and interactions.
            </li>
            <li>
              <strong>Memory and Learning:</strong> Storing, recalling information, and acquiring skills.
            </li>
            <li>
              <strong>Homeostasis:</strong> Maintaining internal balance like temperature and hydration.
            </li>
          </ul>
        </div>

        <div ref={sections.disordersRef} className="Women-menstrual-card">
          <h2>Common Brain Disorders</h2>
          <ul>
            <li>
              <strong>Stroke:</strong> Interruption of blood supply causing weakness and confusion.
            </li>
            <li>
              <strong>Alzheimer's Disease:</strong> Progressive memory loss and confusion.
            </li>
            <li>
              <strong>Parkinson's Disease:</strong> Tremors and stiffness due to loss of dopamine-producing neurons.
            </li>
            <li>
              <strong>Epilepsy:</strong> Abnormal electrical activity causing seizures.
            </li>
            <li>
              <strong>Traumatic Brain Injury (TBI):</strong> External head impact causing headaches and dizziness.
            </li>
          </ul>
        </div>

        <div ref={sections.tipsRef} className="Women-menstrual-card">
          <h2>Tips for Maintaining Brain Health</h2>
          <ul>
            <li><strong>Eat a Brain-Healthy Diet:</strong> Foods rich in omega-3, antioxidants, and vitamins.</li>
            <li><strong>Stay Physically Active:</strong> Exercise improves blood flow to the brain.</li>
            <li><strong>Mental Stimulation:</strong> Activities like puzzles and learning new skills.</li>
            <li><strong>Quality Sleep:</strong> Helps consolidate memory and remove toxins.</li>
            <li><strong>Manage Stress:</strong> Use relaxation techniques like meditation or yoga.</li>
            <li><strong>Stay Socially Connected:</strong> Social interactions reduce cognitive decline.</li>
            <li><strong>Protect the Brain:</strong> Wear helmets during activities and use seat belts.</li>
            <li><strong>Avoid Harmful Substances:</strong> Limit alcohol and avoid drugs.</li>
          </ul>
        </div>

        <div ref={sections.signsRef} className="Women-menstrual-card">
          <h2>Signs of Brain-Related Issues</h2>
          <ul>
            <li>Persistent headaches or migraines.</li>
            <li>Difficulty remembering or concentrating.</li>
            <li>Sudden changes in mood or personality.</li>
            <li>Impaired motor skills or coordination.</li>
            <li>Vision or speech problems.</li>
            <li>Loss of consciousness or confusion.</li>
          </ul>
        </div>

        <div ref={sections.emergenciesRef} className="Women-menstrual-card">
          <h2>Emergencies in Brain Health</h2>
          <ul>
            <li>
              <strong>Stroke:</strong> Act FAST (Face drooping, Arm weakness, Speech difficulty, Time to call emergency services).
            </li>
            <li>
              <strong>Seizures:</strong> Ensure safety and call for help if it lasts longer than 5 minutes.
            </li>
            <li>
              <strong>Concussion:</strong> Seek medical attention for vomiting, confusion, or unconsciousness.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BrainGuide;
