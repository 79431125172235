import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./WomenHealth.css"; // Updated CSS file name

const MenstrualHealth = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const [showTips, setShowTips] = useState(false);

  const toggleTips = () => {
    setShowTips(!showTips);
  };

  const overviewRef = useRef(null);
  const irregularRef = useRef(null);
  const hygieneRef = useRef(null);
  const lifestyleRef = useRef(null);
  const stagesRef = useRef(null);
  const stigmaRef = useRef(null);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="Women-container">
      {/* Back Navigation */}
      <div className="Women-back-arrow" onClick={handleBackClick}>
        ← Women Health
      </div>

      {/* Sidebar */}
      <div className="Women-sidebar">
        <h3>Table of Contents</h3>
        <ul>
          <li onClick={() => scrollToSection(overviewRef)}>Menstrual Cycle Overview</li>
          <li onClick={() => scrollToSection(irregularRef)}>Common Menstrual Issues</li>
          <li onClick={() => scrollToSection(hygieneRef)}>Hygiene and Tips</li>
          <li onClick={() => scrollToSection(lifestyleRef)}>Lifestyle Factors</li>
          <li onClick={() => scrollToSection(stagesRef)}>Menstrual Health Across Life Stages</li>
          <li onClick={() => scrollToSection(stigmaRef)}>Breaking the Stigma</li>
        </ul>
      </div>

      {/* Main Content */}
      <div className="Women-content">
        {/* Menstrual Cycle Overview */}
        <div ref={overviewRef} className="Women-menstrual-card">
          <h2>Menstrual Health and Well-being</h2>
          <h3>Understanding the Menstrual Cycle</h3>
          <p>
            The menstrual cycle is a natural process involving hormonal changes
            that regulate ovulation and menstruation. A typical cycle lasts 21–35
            days, with menstruation lasting 3–7 days.
          </p>
          <h4>Phases of the Menstrual Cycle:</h4>
          <ul>
            <li><strong>Menstrual Phase (Day 1–5):</strong> Shedding of the uterine lining, with symptoms like cramps and fatigue.</li>
            <li><strong>Follicular Phase (Day 1–13):</strong> Preparation for ovulation as estrogen levels rise.</li>
            <li><strong>Ovulation (Day 14):</strong> Release of a mature egg, triggered by a surge in luteinizing hormone.</li>
            <li><strong>Luteal Phase (Day 15–28):</strong> If no fertilization occurs, hormone levels drop, leading to PMS symptoms.</li>
          </ul>
        </div>

        {/* Common Menstrual Issues */}
        <div ref={irregularRef} className="Women-menstrual-card">
          <h3>Common Menstrual Issues</h3>
          <p>Menstrual disorders can affect health and quality of life. Common issues include:</p>
          <ul>
            <li>
              <strong>Dysmenorrhea:</strong> Painful periods with cramps and nausea. Managed with pain relievers and heat therapy.
            </li>
            <li>
              <strong>Amenorrhea:</strong> Absence of periods caused by stress, PCOS, or hormonal imbalances. Treatment varies by cause.
            </li>
            <li>
              <strong>Menorrhagia:</strong> Heavy bleeding that lasts over 7 days. Management may include NSAIDs or hormonal treatments.
            </li>
            <li>
              <strong>Irregular Periods:</strong> Cycles shorter than 21 days or longer than 35 days. Causes include stress and weight changes.
            </li>
            <li>
              <strong>PMS:</strong> Emotional and physical symptoms like mood swings and bloating during the luteal phase.
            </li>
          </ul>
        </div>

        {/* Hygiene and Tips */}
        <div ref={hygieneRef} className="Women-menstrual-card">
          <h3>Menstrual Hygiene and Tips</h3>
          <h4>Hygiene Practices:</h4>
          <ul>
            <li>Change pads, tampons, or cups every 4–8 hours to prevent infections.</li>
            <li>Wash the vulva with warm water; avoid harsh soaps.</li>
            <li>Dispose of sanitary products responsibly; do not flush them.</li>
          </ul>
          <h4>Menstrual Products:</h4>
          <ul>
            <li>
              <strong>Sanitary Pads:</strong> Easy to use; suitable for light to heavy flow.
            </li>
            <li>
              <strong>Tampons:</strong> Inserted internally; ideal for physical activities. Change every 4–6 hours.
            </li>
            <li>
              <strong>Menstrual Cups:</strong> Reusable and eco-friendly; can be worn for up to 12 hours.
            </li>
            <li>
              <strong>Period Underwear:</strong> Absorbent and reusable, perfect for light to moderate flow.
            </li>
          </ul>
        </div>

        {/* Lifestyle Factors */}
        <div ref={lifestyleRef} className="Women-menstrual-card">
          <h3>Lifestyle Factors Impacting Menstrual Health</h3>
          <h4>Diet and Nutrition</h4>
          <ul>
            <li><strong>Iron and Folate:</strong> Include leafy greens, lentils, red meat, and citrus fruits to replenish lost iron and support red blood cell production.</li>
            <li><strong>Magnesium and Calcium:</strong> Nuts, seeds, dairy, and tofu can reduce cramps and improve bone health.</li>
            <li><strong>Hydration:</strong> Staying hydrated reduces bloating and fluid retention.</li>
          </ul>
          <h4>Exercise and Menstrual Health</h4>
          <ul>
            <li><strong>Regular Exercise:</strong> Boosts mood, improves circulation, and reduces cramps.</li>
            <li><strong>Types of Exercise:</strong> Cardio, yoga, and strength training are beneficial during the cycle.</li>
          </ul>
          <h4>Stress and Sleep</h4>
          <ul>
            <li><strong>Stress:</strong> Stress management through meditation can regulate cycles.</li>
            <li><strong>Sleep:</strong> Aim for 7-9 hours to improve hormonal balance.</li>
          </ul>
        </div>

        {/* Menstrual Health Across Life Stages */}
        <div ref={stagesRef} className="Women-menstrual-card">
          <h3>Menstrual Health Across Different Life Stages</h3>
          <h4>Adolescence and Puberty</h4>
          <p>Education on hygiene and tracking is crucial during the first period (menarche).</p>
          <h4>Reproductive Years</h4>
          <p>Regular cycles can be maintained with a balanced lifestyle.</p>
          <h4>Perimenopause and Menopause</h4>
          <p>Transition involves irregular cycles and symptoms like hot flashes; menopause marks the end of menstruation.</p>
        </div>

        {/* Breaking the Stigma */}
        <div ref={stigmaRef} className="Women-menstrual-card">
          <h3>Breaking the Stigma Around Menstruation</h3>
          <h4>Education and Awareness</h4>
          <ul>
            <li>Introduce menstrual health education early in schools.</li>
            <li>Encourage workplace support through menstrual leave and sanitary products.</li>
          </ul>
          <h4>Menstrual Equity</h4>
          <p>Advocate for affordable products, education, and policy changes to ensure dignity for all.</p>
        </div>
      </div>
    </div>
  );
};

export default MenstrualHealth;
