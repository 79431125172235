import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../styles/Navbar.css";
import HSuiteLogo from '../assets/logo.png';
import { FaBars, FaTimes } from "react-icons/fa";

function Navbar() {
  const [active, setActive] = useState("Home");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuItems = [
    { name: "Home", path: "/" },
    { name: "Products", path: "/products" },
    { name: "KYH", path: "/kyh" },
    { name: "Blogs", path: "/blogs" },
    { name: "Contact Us", path: "/contact-us" },
  ];

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="logo">
        <Link
          to="/"
          onClick={() => setActive("Home")} // Set active to "Home" when clicking logo
        >
          <img src={HSuiteLogo} alt="HSuite Logo" />
        </Link>
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        {isMenuOpen ? <FaTimes /> : <FaBars />}
      </div>
      <ul className={`menu ${isMenuOpen ? "open" : ""}`}>
        {menuItems.map((item) => (
          <li key={item.name}>
            <Link
              to={item.path}
              className={active === item.name ? "active" : ""}
              onClick={() => {
                setActive(item.name);
                setIsMenuOpen(false); // Close the menu after clicking
              }}
            >
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Navbar;
