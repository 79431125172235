import React from "react";
import { FaInstagram, FaLinkedin, FaTwitter, FaWhatsapp } from "react-icons/fa";
import "../styles/Footer.css";
import HSuiteLogo from "../assets/logo.png"; // Update with the correct path to your logo file

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Logo Section */}

        {/* Links Section */}
        <div className="footer-links">
          {/* About Us */}
          <div>
            <h3 className="footer-head">Quick Links</h3>
            <ul>
              <li>
                <a href="/kyh">KYH</a>
              </li>
              <li>
                <a href="/blogs">Blogs</a>
              </li>
              <li>
                <a href="/contact-us">Contact Us</a>
              </li>
            </ul>
          </div>

          {/* Products */}
          <div>
            <h3 className="footer-head">Products</h3>
            <ul>
              <li>
                <a href="/receptionist-dashboard">Receptionist Dashboard</a>
              </li>
              <li>
                <a href="/doctor-dashboard">Doctor Dashboard</a>
              </li>
              <li>
                <a href="/pharmacy-dashboard">Pharmacy Dashboard</a>
              </li>
              <li>
                <a href="/nurse-dashboard">Nurse Dashboard</a>
              </li>
              <li>
                <a href="/lab-dashboard">Lab Dashboard</a>
              </li>
              <li>
                <a href="/radiology-dashboard">Radiology Dashboard</a>
              </li>
            </ul>
          </div>

          {/* Privacy and Security */}
          <div>
            <h3 className="footer-head">Privacy and Security</h3>
            <ul>
              <li>
                <a href="/terms-and-conditions">Terms and Conditions</a>
              </li>
              <li>
                <a href="/security">Security</a>
              </li>
              <li>
                <a href="/privacy-policy">Privacy Policy</a>
              </li>
            </ul>
          </div>
        </div>

        <div className="footer-logo">
          <img src={HSuiteLogo} alt="HSuite Logo" className="hsuite-logo" />
          {/* Social Icons */}
          <div className="social-icons">
            <a href="https://www.instagram.com/hsuite.in" target="_blank" rel="noopener noreferrer">
              <FaInstagram size={30} />
            </a>
            <a href="https://www.linkedin.com/company/h-suite/" target="_blank" rel="noopener noreferrer">
              <FaLinkedin size={30} />
            </a>
            <a href="https://chat.whatsapp.com/BQJi5hxAVfXGbnNyZxocMR" target="_blank" rel="noopener noreferrer">
              <FaWhatsapp size={30} />
            </a>
            {/* <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
              <FaTwitter size={30} />
            </a> */}
          </div>
          {/* Copyright Section */}
          <p className="copyright">
            © 2025 HSuite. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
