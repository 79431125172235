import React from "react";
import "../styles/Products.css"

function Products() {
  return (
    <div style={{ textAlign: "center", marginTop: "100px", marginBottom: "200px" }}>
      <h1>Coming Soon</h1>
    </div>
  );
}

export default Products;
