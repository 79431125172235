import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

const BreastCancer = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const sections = {
    overviewRef: useRef(null),
    symptomsRef: useRef(null),
    causesRef: useRef(null),
    diagnosisRef: useRef(null),
    treatmentRef: useRef(null),
  };

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="Women-container">
      {/* Back Navigation */}
      <div className="Women-back-arrow" onClick={handleBackClick}>
        ← Back
      </div>

      {/* Sidebar */}
      <div className="Women-sidebar">
        <h3>Table of Contents</h3>
        <ul>
          <li onClick={() => scrollToSection(sections.overviewRef)}>Overview</li>
          <li onClick={() => scrollToSection(sections.symptomsRef)}>Symptoms</li>
          <li onClick={() => scrollToSection(sections.causesRef)}>Causes and Risk Factors</li>
          <li onClick={() => scrollToSection(sections.diagnosisRef)}>Diagnosis</li>
          <li onClick={() => scrollToSection(sections.treatmentRef)}>Treatment</li>
        </ul>
      </div>

      {/* Main Content */}
      <div className="Women-content">
        {/* Overview */}
        <div ref={sections.overviewRef} className="Women-menstrual-card">
          <h2>Overview</h2>
          <p>
            Breast cancer develops in the cells of the breast tissue, most commonly in the milk ducts or lobules. It is one of the most common cancers among women but can also occur in men.
          </p>
        </div>

        {/* Symptoms */}
        <div ref={sections.symptomsRef} className="Women-menstrual-card">
          <h3>Symptoms</h3>
          <ul>
            <li>Lump or thickening in the breast or underarm</li>
            <li>Change in breast shape or size</li>
            <li>Nipple discharge (other than breast milk), possibly blood-stained</li>
            <li>Dimpling or puckering of breast skin</li>
            <li>Redness or flaky skin around the nipple area</li>
            <li>Persistent breast pain</li>
          </ul>
        </div>

        {/* Causes and Risk Factors */}
        <div ref={sections.causesRef} className="Women-menstrual-card">
          <h3>Causes and Risk Factors</h3>
          <ul>
            <li><strong>Genetics:</strong> Mutations in BRCA1 and BRCA2 genes</li>
            <li><strong>Hormonal Factors:</strong> Prolonged estrogen exposure</li>
            <li><strong>Lifestyle:</strong> Smoking, obesity, alcohol consumption</li>
            <li><strong>Age:</strong> Risk increases with age</li>
            <li><strong>Family History:</strong> Having a first-degree relative with breast cancer</li>
          </ul>
        </div>

        {/* Diagnosis */}
        <div ref={sections.diagnosisRef} className="Women-menstrual-card">
          <h3>Diagnosis</h3>
          <ul>
            <li><strong>Physical Examination:</strong> Checking for lumps or irregularities</li>
            <li><strong>Imaging Tests:</strong> Mammogram, ultrasound, MRI</li>
            <li><strong>Biopsy:</strong> Removing a sample of tissue for lab analysis</li>
            <li><strong>Genetic Testing:</strong> Checking for BRCA mutations</li>
          </ul>
        </div>

        {/* Treatment */}
        <div ref={sections.treatmentRef} className="Women-menstrual-card">
          <h3>Treatment</h3>
          <ul>
            <li><strong>Surgery:</strong> Lumpectomy or mastectomy</li>
            <li><strong>Radiation Therapy:</strong> Targeted radiation to kill cancer cells</li>
            <li><strong>Chemotherapy:</strong> Drugs to shrink or kill cancer cells</li>
            <li><strong>Hormonal Therapy:</strong> Tamoxifen or aromatase inhibitors</li>
            <li><strong>Targeted Therapy:</strong> Drugs like Herceptin (trastuzumab) for HER2-positive cancers</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BreastCancer;
