import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./WomenHealth.css"

const EyeColorTest = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const sections = {
    introductionRef: useRef(null),
    whatIsTestRef: useRef(null),
    importanceRef: useRef(null),
    determinationRef: useRef(null),
    typesColorsRef: useRef(null),
    typesTestsRef: useRef(null),
    identificationRef: useRef(null),
    funFactsRef: useRef(null),
    applicationsRef: useRef(null),
    mythsRef: useRef(null),
    resourcesRef: useRef(null),
  };

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="Women-container">
      {/* Back Navigation */}
      <div className="Women-back-arrow" onClick={handleBackClick}>
        ← Back
      </div>

      {/* Sidebar */}
      <div className="Women-sidebar">
        <h3>Table of Contents</h3>
        <ul>
          <li onClick={() => scrollToSection(sections.introductionRef)}>Introduction</li>
          <li onClick={() => scrollToSection(sections.whatIsTestRef)}>What is an Eye Color Test?</li>
          <li onClick={() => scrollToSection(sections.importanceRef)}>Why Are Eye Color Tests Important?</li>
          <li onClick={() => scrollToSection(sections.determinationRef)}>
            How Eye Color is Determined
          </li>
          <li onClick={() => scrollToSection(sections.typesColorsRef)}>Types of Eye Colors</li>
          <li onClick={() => scrollToSection(sections.typesTestsRef)}>Types of Eye Color Tests</li>
          <li onClick={() => scrollToSection(sections.identificationRef)}>How to Identify Your Eye Color</li>
          <li onClick={() => scrollToSection(sections.funFactsRef)}>Fun Facts About Eye Color</li>
          <li onClick={() => scrollToSection(sections.applicationsRef)}>Applications of Eye Color Testing</li>
          <li onClick={() => scrollToSection(sections.mythsRef)}>Myths About Eye Color</li>
          <li onClick={() => scrollToSection(sections.resourcesRef)}>Recommended Resources</li>
        </ul>
      </div>

      {/* Main Content */}
      <div className="Women-content">
        {/* Introduction */}
        <div ref={sections.introductionRef} className="Women-menstrual-card">
          <h2>Introduction</h2>
          <p>
            Eye color is one of the most fascinating traits, determined by the interaction of genetics and pigments in the iris. Eye color tests are used to understand eye pigmentation, genetic traits, and, in some cases, even eye health. This page provides insights into how eye color is determined and how modern science can analyze it.
          </p>
        </div>

        {/* What is an Eye Color Test */}
        <div ref={sections.whatIsTestRef} className="Women-menstrual-card">
          <h3>What is an Eye Color Test?</h3>
          <p>
            An eye color test involves examining the pigmentation of the iris and understanding the genetic and hereditary factors that influence eye color. While often done for curiosity, it can also have applications in genetics, health, and ancestry research.
          </p>
        </div>

        {/* Why Are Eye Color Tests Important */}
        <div ref={sections.importanceRef} className="Women-menstrual-card">
          <h3>Why Are Eye Color Tests Important?</h3>
          <ul>
            <li>
              <strong>Genetic Understanding:</strong> Helps explore the genetics of eye color inheritance.
            </li>
            <li>
              <strong>Health Insights:</strong> Eye pigmentation may indicate certain health risks.
            </li>
            <li>
              <strong>Ancestry Research:</strong> Provides clues about your genetic heritage and lineage.
            </li>
          </ul>
        </div>

        {/* How Eye Color is Determined */}
        <div ref={sections.determinationRef} className="Women-menstrual-card">
          <h3>How Eye Color is Determined</h3>
          <ul>
            <li>
              <strong>Melanin Levels:</strong> The amount of melanin in the iris determines how dark your eye color is.
              <ul>
                <li><strong>Brown Eyes:</strong> High melanin content.</li>
                <li><strong>Blue Eyes:</strong> Low melanin content.</li>
              </ul>
            </li>
            <li>
              <strong>Rayleigh Scattering:</strong> The way light scatters in the iris affects the perception of lighter eye colors.
            </li>
            <li>
              <strong>Genetics:</strong> Multiple genes, especially the OCA2 and HERC2 genes, influence eye color. Eye color inheritance is a mix of dominant and recessive traits.
            </li>
          </ul>
        </div>

        {/* Types of Eye Colors */}
        <div ref={sections.typesColorsRef} className="Women-menstrual-card">
          <h3>Types of Eye Colors</h3>
          <ul>
            <li><strong>Brown:</strong> The most common globally, caused by high melanin levels.</li>
            <li><strong>Blue:</strong> Low melanin, typically associated with European ancestry.</li>
            <li><strong>Green:</strong> A mix of low melanin and a yellowish pigment called lipochrome.</li>
            <li><strong>Hazel:</strong> A combination of brown, green, or amber tones.</li>
            <li><strong>Gray:</strong> Rare and influenced by the density of collagen in the stroma.</li>
            <li><strong>Amber:</strong> Golden or yellow tones caused by lipochrome.</li>
          </ul>
        </div>

        {/* Types of Eye Color Tests */}
        <div ref={sections.typesTestsRef} className="Women-menstrual-card">
          <h3>Types of Eye Color Tests</h3>
          <h4>5.1. Genetic Eye Color Test</h4>
          <p>Involves a DNA test to predict potential eye color based on your genes. Commonly done with ancestry testing kits like 23andMe.</p>
          <h4>5.2. Visual Eye Color Analysis</h4>
          <p>A simple analysis by observing the eye in natural light to identify the color.</p>
          <h4>5.3. Eye Pigmentation Test</h4>
          <p>Conducted to assess pigmentation in the iris, sometimes using specialized imaging techniques.</p>
          <h4>5.4. Online Eye Color Quizzes</h4>
          <p>Fun quizzes that predict eye color personality traits (purely for entertainment).</p>
        </div>

        {/* How to Identify Your Eye Color */}
        <div ref={sections.identificationRef} className="Women-menstrual-card">
          <h3>How to Identify Your Eye Color</h3>
          <ul>
            <li><strong>Use Natural Lighting:</strong> Look in the mirror under natural daylight.</li>
            <li><strong>Compare with Charts:</strong> Use an eye color chart for reference.</li>
            <li><strong>Avoid Colored Lenses:</strong> Make sure you're not wearing tinted contact lenses during the assessment.</li>
          </ul>
        </div>

        {/* Fun Facts About Eye Color */}
        <div ref={sections.funFactsRef} className="Women-menstrual-card">
          <h3>Fun Facts About Eye Color</h3>
          <ul>
            <li><strong>Rare Colors:</strong> Green is the rarest natural eye color, occurring in about 2% of the population.</li>
            <li><strong>Eye Color Can Change:</strong> For some people, eye color may change subtly with age or lighting.</li>
            <li><strong>Heterochromia:</strong> A condition where one eye is a different color from the other.</li>
          </ul>
        </div>

        {/* Applications of Eye Color Testing */}
        <div ref={sections.applicationsRef} className="Women-menstrual-card">
          <h3>Applications of Eye Color Testing</h3>
          <ul>
            <li><strong>Ancestry Research:</strong> Eye color tests help track genetic lineage.</li>
            <li><strong>Forensic Studies:</strong> Used in criminal investigations to identify individuals.</li>
            <li><strong>Cosmetics & Fashion:</strong> Personalized beauty recommendations based on eye color.</li>
          </ul>
        </div>

        {/* Myths About Eye Color */}
        <div ref={sections.mythsRef} className="Women-menstrual-card">
          <h3>Myths About Eye Color</h3>
          <ul>
            <li><strong>Eye Color and Personality:</strong> No scientific evidence links eye color to personality traits.</li>
            <li><strong>Eye Color Equals Vision Strength:</strong> Eye color has no direct correlation with vision quality or strength.</li>
          </ul>
        </div>

        {/* Recommended Resources */}
        <div ref={sections.resourcesRef} className="Women-menstrual-card">
          <h3>Recommended Resources</h3>
          <ul>
            <li>
              <strong>Eye Color Charts:</strong> Available on platforms like{" "}
              <a href="https://learn.genetics.utah.edu" target="_blank" rel="noreferrer">
                Genetic Science Learning Center
              </a>.
            </li>
            <li>
              <strong>Ancestry Kits:</strong>{" "}
              <a href="https://www.23andme.com" target="_blank" rel="noreferrer">
                23andMe
              </a>{" "}
              or{" "}
              <a href="https://www.ancestry.com" target="_blank" rel="noreferrer">
                AncestryDNA
              </a>.
            </li>
            <li>
              <strong>Articles:</strong> "Eye Color and Genetics" by the National Institutes of Health (NIH).
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EyeColorTest;
