import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

const SkinCancer = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const sections = {
    overviewRef: useRef(null),
    symptomsRef: useRef(null),
    causesRef: useRef(null),
    diagnosisRef: useRef(null),
    treatmentRef: useRef(null),
  };

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="Women-container">
      {/* Back Navigation */}
      <div className="Women-back-arrow" onClick={handleBackClick}>
        ← Back
      </div>

      {/* Sidebar */}
      <div className="Women-sidebar">
        <h3>Table of Contents</h3>
        <ul>
          <li onClick={() => scrollToSection(sections.overviewRef)}>Overview</li>
          <li onClick={() => scrollToSection(sections.symptomsRef)}>Symptoms</li>
          <li onClick={() => scrollToSection(sections.causesRef)}>Causes and Risk Factors</li>
          <li onClick={() => scrollToSection(sections.diagnosisRef)}>Diagnosis</li>
          <li onClick={() => scrollToSection(sections.treatmentRef)}>Treatment</li>
        </ul>
      </div>

      {/* Main Content */}
      <div className="Women-content">
        {/* Overview */}
        <div ref={sections.overviewRef} className="Women-menstrual-card">
          <h2>Overview</h2>
          <p>
            Skin cancer is the most common type of cancer globally. It occurs when skin cells grow abnormally, often due to ultraviolet (UV) radiation exposure. Types include melanoma, basal cell carcinoma (BCC), and squamous cell carcinoma (SCC).
          </p>
        </div>

        {/* Symptoms */}
        <div ref={sections.symptomsRef} className="Women-menstrual-card">
          <h3>Symptoms</h3>
          <ul>
            <li>New or unusual growths or moles on the skin</li>
            <li>Changes in size, shape, or color of existing moles</li>
            <li>Non-healing sores or lesions</li>
            <li>Red, scaly patches or lumps on the skin</li>
            <li>Dark streaks under fingernails or toenails (for melanoma)</li>
          </ul>
          <p><strong>Melanoma Specific:</strong></p>
          <p>
            Often appears as a mole with asymmetry, irregular borders, multiple colors, and changes over time (ABCDE rule).
          </p>
        </div>

        {/* Causes and Risk Factors */}
        <div ref={sections.causesRef} className="Women-menstrual-card">
          <h3>Causes and Risk Factors</h3>
          <ul>
            <li><strong>UV Exposure:</strong> Excessive sunlight or tanning beds</li>
            <li><strong>Fair Skin:</strong> Higher risk due to less melanin</li>
            <li><strong>Family History:</strong> Genetic predisposition</li>
            <li><strong>Moles:</strong> Having many or atypical moles</li>
            <li><strong>Weakened Immune System:</strong> Due to conditions or medications</li>
          </ul>
        </div>

        {/* Diagnosis */}
        <div ref={sections.diagnosisRef} className="Women-menstrual-card">
          <h3>Diagnosis</h3>
          <ul>
            <li><strong>Skin Examination:</strong> Visual inspection by a dermatologist</li>
            <li><strong>Dermatoscopy:</strong> Magnified view of suspicious lesions</li>
            <li><strong>Biopsy:</strong> Testing skin tissue for cancer cells</li>
          </ul>
        </div>

        {/* Treatment */}
        <div ref={sections.treatmentRef} className="Women-menstrual-card">
          <h3>Treatment</h3>
          <ul>
            <li><strong>Surgical Removal:</strong> Excision of affected skin</li>
            <li><strong>Cryotherapy:</strong> Freezing and destroying abnormal tissue</li>
            <li><strong>Radiation Therapy:</strong> For advanced cases</li>
            <li><strong>Chemotherapy:</strong> For aggressive or metastatic cancers</li>
            <li><strong>Targeted Therapy:</strong> For melanoma with specific genetic mutations</li>
            <li><strong>Immunotherapy:</strong> Drugs like pembrolizumab for melanoma</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SkinCancer;
