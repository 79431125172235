import React, { useState } from "react";
import "../styles/Timeline.css";

const ContactUsAndTimeline = () => {
  // State for form fields
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [statusMessage, setStatusMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Loading state

  // Handle form field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading spinner

    try {
      const response = await fetch("https://hsuite-landing-page.onrender.com/api/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const message = await response.text();
        setStatusMessage(message);
        setFormData({ name: "", email: "", phone: "", message: "" }); // Clear form fields
      } else {
        const errorMessage = await response.text();
        setStatusMessage(`Error: ${errorMessage}`);
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      setStatusMessage("Error: Unable to send your message. Please try again later.");
    } finally {
      setIsLoading(false); // Stop loading spinner
    }
  };

  return (
    <div className="container">
      {/* Left: Contact Us Section */}
      <div className="contact-us-section">
        <h2 className="contact-heading">Contact Us</h2>
        <p className="contactus-description">Book a free Demo Now!</p>
        <form className="contact-form" onSubmit={handleSubmit}>
          <label className="contact-label">
            Name
            <input
              type="text"
              name="name"
              placeholder="Enter your name"
              className="contact-input"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
          </label>
          <label className="contact-label">
            Email
            <input
              type="email"
              name="email"
              placeholder="Enter your email"
              className="contact-input"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </label>
          <label className="contact-label">
            Phone Number
            <input
              type="text"
              name="phone"
              placeholder="Enter your phone number"
              className="contact-input"
              value={formData.phone}
              onChange={handleInputChange}
              required
            />
          </label>
          <label className="contact-label">
            Message
            <textarea
              name="message"
              placeholder="Enter your message"
              className="contact-textarea"
              value={formData.message}
              onChange={handleInputChange}
              required
            ></textarea>
          </label>
          <button
            type="submit"
            className="submit-button"
            disabled={isLoading} // Disable button while loading
          >
            {isLoading ? (
              <span className="loading-spinner"></span> // Show spinner
            ) : (
              "Submit"
            )}
          </button>
        </form>
        {statusMessage && <p className="status-message">{statusMessage}</p>}
      </div>

      {/* Right: Timeline Section */}
      <div className="timeline-section">
        <h2 className="timeline-heading">Customization Journey</h2>
        <p className="timeline-description">
          While our product is designed to fit your needs out of the box, we understand that every hospital is unique. Here’s how we work with you to create a solution tailored specifically to your requirements. Your vision, our expertise!
        </p>
        <div className="timeline">
          <div className="timeline-item">
            <div className="circle"></div>
            <div className="content">
              <h3>Initial Visit to hospital</h3>
            </div>
          </div>
          <div className="timeline-item">
            <div className="circle"></div>
            <div className="content">
              <h3>Gathering Requirements from hospital</h3>
            </div>
          </div>
          <div className="timeline-item">
            <div className="circle"></div>
            <div className="content">
              <h3>Tailored Design to address the unique needs</h3>
            </div>
          </div>
          <div className="timeline-item">
            <div className="circle"></div>
            <div className="content">
              <h3>Feedback & Review from the hospital</h3>
            </div>
          </div>
          <div className="timeline-item">
            <div className="circle"></div>
            <div className="content">
              <h3>Customizing the product to the exact specifications</h3>
            </div>
          </div>
          <div className="timeline-item">
            <div className="circle"></div>
            <div className="content">
              <h3>Product Delivery & Launch</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsAndTimeline;
