import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './KYH.css'; 
import blooddonor from './icons/blood-donation.png';
import bloodtype from './icons/blood-type.png';
import { ReactComponent as AnaemiaIcon } from './icons/anaemia.svg';

const Blood = () => {
  const navigate = useNavigate();  // useNavigate hook to handle navigation

  const handleBackClick = () => {
    navigate(-1);  // This will navigate back to the previous page
  };
    return (
        <div className="kyh-container">
            <div className="back-arrow" onClick={handleBackClick}>
                ← KYH
            </div>
          <h1 className='kyh-heading'>Eye Care</h1>
          <div className="kyh-row1">
            <div className="kyh-card">
              <Link to="/kyh/anemia">
                <AnaemiaIcon className="kyh-image" />
                <h3>Anemia</h3>
              </Link>
            </div>
            {/* <div className="kyh-card">
              <Link to="/kyh/blood-banks">
              <AnaemiaIcon className="kyh-image" />
                <h3>Blood Banks</h3>
              </Link>
            </div> */}
            <div className="kyh-card">
              <Link to="/kyh/blood-donor">
              <img src={blooddonor} alt="blood donor" className="women-image" />
                <h3>Blood Donor Chart</h3>
              </Link>
            </div>
            <div className="kyh-card">
              <Link to="/kyh/blood-type">
                <img src={bloodtype} alt="blood type" className="women-image" />
                <h3>Blood Test Types</h3>
              </Link>
            </div>
          </div>
          {/* <div className="kyh-row2">
            
          </div> */}
        </div>
    );
};

export default Blood;
