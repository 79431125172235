import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import pregnancyIcon from './icons/embryo.png';
import menstrualHealth from './icons/menstrual-cycle.png';
import menopause from './icons/menopause.png';
import breast from './icons/breast.png';
import { ReactComponent as EyeSightIcon } from './icons/eye.svg';
import { ReactComponent as WomenIcon } from './icons/women.svg';

function WomenHealth() {
    const navigate = useNavigate();  // useNavigate hook to handle navigation

  const handleBackClick = () => {
    navigate(-1);  // This will navigate back to the previous page
  };
    return (
        <div className="kyh-container">
            <div className="back-arrow" onClick={handleBackClick}>
                ← KYH
            </div>
          <h1 className='kyh-heading'>Women Health</h1>
          <div className="kyh-row1">
            <div className="kyh-card">
              <Link to="/kyh/menstrual-health">
                <img src={menstrualHealth} alt="menstrualHealth" className="women-image" />
                <h3>Menstrual Health</h3>
              </Link>
            </div>
            <div className="kyh-card">
              <Link to="/kyh/pregnancy">
              <img src={pregnancyIcon} alt="Pregnant Icon" className="women-image" />
                <h3>Pregnancy</h3>
              </Link>
            </div>
            <div className="kyh-card">
              <Link to="/kyh/menopause">
              <img src={menopause} alt="menopause Icon" className="women-image" />
                <h3>Menopause</h3>
              </Link>
            </div>
          </div>
          <div className="kyh-row2">
            <div className="kyh-card">
              <Link to="/kyh/breast-health">
              <img src={breast} alt="Breast Health Icon" className="women-image" />
                <h3>Breast Health</h3>
              </Link>
            </div>
          </div>

        </div>
    );
}

export default WomenHealth;