import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

const BloodTestGuide = () => {
  const navigate = useNavigate();

  const sections = {
    cbcRef: useRef(null),
    glucoseRef: useRef(null),
    lipidRef: useRef(null),
    liverRef: useRef(null),
    kidneyRef: useRef(null),
    thyroidRef: useRef(null),
    coagulationRef: useRef(null),
    inflammationRef: useRef(null),
    vitaminsRef: useRef(null),
    hormoneRef: useRef(null),
    infectiousRef: useRef(null),
    cancerRef: useRef(null),
    gasRef: useRef(null),
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="Women-container">
      <div className="Women-back-arrow" onClick={handleBackClick}>
        ← Back
      </div>
      <div className="Women-sidebar">
        <h3>Table of Contents</h3>
        <ul>
          <li onClick={() => scrollToSection(sections.cbcRef)}>Complete Blood Count (CBC)</li>
          <li onClick={() => scrollToSection(sections.glucoseRef)}>Blood Glucose Test</li>
          <li onClick={() => scrollToSection(sections.lipidRef)}>Lipid Profile</li>
          <li onClick={() => scrollToSection(sections.liverRef)}>Liver Function Tests (LFTs)</li>
          <li onClick={() => scrollToSection(sections.kidneyRef)}>Kidney Function Tests</li>
          <li onClick={() => scrollToSection(sections.thyroidRef)}>Thyroid Function Tests</li>
          <li onClick={() => scrollToSection(sections.coagulationRef)}>Coagulation Panel</li>
          <li onClick={() => scrollToSection(sections.inflammationRef)}>CRP and ESR Tests</li>
          <li onClick={() => scrollToSection(sections.vitaminsRef)}>Vitamin and Mineral Tests</li>
          <li onClick={() => scrollToSection(sections.hormoneRef)}>Hormone Tests</li>
          <li onClick={() => scrollToSection(sections.infectiousRef)}>Infectious Disease Tests</li>
          <li onClick={() => scrollToSection(sections.cancerRef)}>Cancer Marker Tests</li>
          <li onClick={() => scrollToSection(sections.gasRef)}>Blood Gas Test</li>
        </ul>
      </div>
      <div className="Women-content">
        <div ref={sections.cbcRef} className="Women-menstrual-card">
          <h2>Complete Blood Count (CBC)</h2>
          <p><strong>Purpose:</strong> Evaluates overall health and detects conditions like anemia, infections, and blood disorders.</p>
          <ul>
            <li>Red Blood Cells (RBCs): Measures oxygen-carrying cells.</li>
            <li>Hemoglobin (Hb): Oxygen-carrying protein in RBCs.</li>
            <li>Hematocrit (Hct): Proportion of RBCs in blood.</li>
            <li>White Blood Cells (WBCs): Indicates immune system activity or infections.</li>
            <li>Platelets: Helps evaluate clotting ability.</li>
          </ul>
        </div>
        
        {/* Repeat similar sections for all other tests */}
        <div ref={sections.glucoseRef} className="Women-menstrual-card">
          <h2>Blood Glucose Test</h2>
          <p><strong>Purpose:</strong> Diagnoses and monitors diabetes or prediabetes.</p>
          <ul>
            <li>Fasting Blood Sugar (FBS): Measures glucose levels after fasting for 8 hours.</li>
            <li>Random Blood Sugar (RBS): Checks glucose levels at any time.</li>
            <li>HbA1c Test: Measures average blood sugar levels over the past 3 months.</li>
          </ul>
        </div>

        <div ref={sections.lipidRef} className="Women-menstrual-card">
          <h2>Lipid Profile</h2>
          <p><strong>Purpose:</strong> Evaluates heart health by analyzing cholesterol and fat levels.</p>
          <ul>
            <li>Total Cholesterol: Overall cholesterol in blood.</li>
            <li>LDL (Low-Density Lipoprotein): "Bad" cholesterol linked to heart disease.</li>
            <li>HDL (High-Density Lipoprotein): "Good" cholesterol that helps remove excess cholesterol.</li>
            <li>Triglycerides: Type of fat in blood.</li>
          </ul>
        </div>

        <div ref={sections.liverRef} className="Women-menstrual-card">
          <h2>Liver Function Tests (LFTs)</h2>
          <p><strong>Purpose:</strong> Assesses liver health and function.</p>
          <ul>
            <li>Alanine Transaminase (ALT): Detects liver damage.</li>
            <li>Aspartate Transaminase (AST): Indicates liver and heart health.</li>
            <li>Alkaline Phosphatase (ALP): Checks for liver or bone disorders.</li>
            <li>Bilirubin: Measures bile pigment to assess liver function.</li>
            <li>Albumin and Total Protein: Evaluates protein levels for liver and kidney health.</li>
          </ul>
        </div>

        <div ref={sections.kidneyRef} className="Women-menstrual-card">
          <h2>Kidney Function Tests</h2>
          <p><strong>Purpose:</strong> Monitors kidney health and detects conditions like kidney disease.</p>
          <ul>
            <li>Blood Urea Nitrogen (BUN): Measures urea levels to evaluate kidney function.</li>
            <li>Creatinine: Assesses waste filtration efficiency of kidneys.</li>
            <li>Glomerular Filtration Rate (GFR): Estimates kidney filtering capacity.</li>
          </ul>
        </div>

        <div ref={sections.thyroidRef} className="Women-menstrual-card">
          <h2>Thyroid Function Tests</h2>
          <p><strong>Purpose:</strong> Evaluates thyroid gland function and detects disorders like hypothyroidism or hyperthyroidism.</p>
          <ul>
            <li>TSH (Thyroid-Stimulating Hormone): Determines thyroid gland activity.</li>
            <li>T3 (Triiodothyronine) and T4 (Thyroxine): Measures hormone levels produced by the thyroid.</li>
          </ul>
        </div>

        <div ref={sections.coagulationRef} className="Women-menstrual-card">
          <h2>Coagulation Panel</h2>
          <p><strong>Purpose:</strong> Evaluates blood clotting ability to detect bleeding disorders or monitor anticoagulant therapies.</p>
          <ul>
            <li>Prothrombin Time (PT): Measures clotting time.</li>
            <li>Activated Partial Thromboplastin Time (aPTT): Assesses clotting factors.</li>
            <li>INR (International Normalized Ratio): Monitors blood-thinning medication.</li>
          </ul>
        </div>

        <div ref={sections.inflammationRef} className="Women-menstrual-card">
          <h2>C-Reactive Protein (CRP) and ESR Tests</h2>
          <p><strong>Purpose:</strong> Detects inflammation in the body caused by infections, autoimmune diseases, or chronic conditions.</p>
          <ul>
            <li>CRP Test: Measures levels of C-reactive protein, which rises during inflammation.</li>
            <li>ESR (Erythrocyte Sedimentation Rate): Assesses the speed at which red blood cells settle, indicating inflammation.</li>
          </ul>
        </div>

        <div ref={sections.vitaminsRef} className="Women-menstrual-card">
          <h2>Vitamin and Mineral Tests</h2>
          <p><strong>Purpose:</strong> Checks for deficiencies that can cause fatigue, weak immunity, or bone health issues.</p>
          <ul>
            <li>Vitamin D Test: Evaluates bone health.</li>
            <li>Vitamin B12 and Folate: Detects deficiencies causing anemia.</li>
            <li>Iron Studies: Measures iron levels for conditions like anemia.</li>
          </ul>
        </div>

        <div ref={sections.hormoneRef} className="Women-menstrual-card">
          <h2>Hormone Tests</h2>
          <p><strong>Purpose:</strong> Diagnoses hormonal imbalances or related health conditions.</p>
          <ul>
            <li>Estrogen and Progesterone: Monitors reproductive health in women.</li>
            <li>Testosterone: Assesses male reproductive health or hormonal issues.</li>
            <li>Cortisol: Evaluates stress hormone levels for adrenal health.</li>
            <li>Insulin: Monitors insulin production for diabetes management.</li>
          </ul>
        </div>

        <div ref={sections.infectiousRef} className="Women-menstrual-card">
          <h2>Infectious Disease Tests</h2>
          <p><strong>Purpose:</strong> Screens for infections and diseases caused by bacteria, viruses, or parasites.</p>
          <ul>
            <li>HIV Test: Detects human immunodeficiency virus.</li>
            <li>Hepatitis Panel: Screens for hepatitis A, B, and C.</li>
            <li>Malaria Test: Identifies malaria parasites in the blood.</li>
            <li>Dengue Test: Detects the dengue virus.</li>
          </ul>
        </div>

        <div ref={sections.cancerRef} className="Women-menstrual-card">
          <h2>Cancer Marker Tests</h2>
          <p><strong>Purpose:</strong> Identifies cancer-related proteins or markers in the blood.</p>
          <ul>
            <li>PSA (Prostate-Specific Antigen): Screens for prostate cancer.</li>
            <li>CA-125: Detects ovarian cancer.</li>
            <li>CEA (Carcinoembryonic Antigen): Monitors colon, pancreatic, or breast cancer.</li>
          </ul>
        </div>

        <div ref={sections.gasRef} className="Women-menstrual-card">
          <h2>Blood Gas Test</h2>
          <p><strong>Purpose:</strong> Measures oxygen and carbon dioxide levels in the blood to assess lung and metabolic function.</p>
          <ul>
            <li>pH Levels: Indicates blood acidity.</li>
            <li>Oxygen (O2) and Carbon Dioxide (CO2) Levels: Assesses respiratory health.</li>
            <li>Bicarbonate: Evaluates metabolic function.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BloodTestGuide;
