import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

const Anemia = () => {
  const navigate = useNavigate();

  const sections = {
    overviewRef: useRef(null),
    typesRef: useRef(null),
    symptomsRef: useRef(null),
    causesRef: useRef(null),
    diagnosisRef: useRef(null),
    treatmentRef: useRef(null),
    complicationsRef: useRef(null),
    preventionRef: useRef(null),
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="Women-container">
      <div className="Women-back-arrow" onClick={handleBackClick}>
        ← Back
      </div>
      <div className="Women-sidebar">
        <h3>Table of Contents</h3>
        <ul>
          <li onClick={() => scrollToSection(sections.overviewRef)}>Overview</li>
          <li onClick={() => scrollToSection(sections.typesRef)}>Types of Anemia</li>
          <li onClick={() => scrollToSection(sections.symptomsRef)}>Symptoms</li>
          <li onClick={() => scrollToSection(sections.causesRef)}>Causes</li>
          <li onClick={() => scrollToSection(sections.diagnosisRef)}>Diagnosis</li>
          <li onClick={() => scrollToSection(sections.treatmentRef)}>Treatment</li>
          <li onClick={() => scrollToSection(sections.complicationsRef)}>Complications</li>
          <li onClick={() => scrollToSection(sections.preventionRef)}>Prevention</li>
        </ul>
      </div>
      <div className="Women-content">
        <div ref={sections.overviewRef} className="Women-menstrual-card">
          <h2>Anemia: Overview</h2>
          <p>
            Anemia is a condition where the body lacks enough healthy red blood cells (RBCs) or hemoglobin to carry oxygen to tissues. It can range from mild to severe and is one of the most common blood disorders.
          </p>
        </div>
        <div ref={sections.typesRef} className="Women-menstrual-card">
          <h2>Types of Anemia</h2>
          <ul>
            <li>
              <strong>Iron-Deficiency Anemia:</strong> Caused by insufficient iron levels due to blood loss, poor diet, or absorption issues.
            </li>
            <li>
              <strong>Vitamin-Deficiency Anemia:</strong> Results from lack of vitamin B12 or folate, leading to abnormally large red blood cells.
            </li>
            <li>
              <strong>Aplastic Anemia:</strong> Rare but serious; the bone marrow fails to produce enough blood cells due to autoimmune diseases, infections, or toxins.
            </li>
            <li>
              <strong>Hemolytic Anemia:</strong> Red blood cells are destroyed faster than they are replaced. Can be inherited or acquired.
            </li>
            <li>
              <strong>Sickle Cell Anemia:</strong> Genetic disorder causing abnormally shaped red blood cells that block blood flow.
            </li>
            <li>
              <strong>Thalassemia:</strong> Genetic condition leading to fewer or defective red blood cells.
            </li>
          </ul>
        </div>
        <div ref={sections.symptomsRef} className="Women-menstrual-card">
          <h2>Symptoms of Anemia</h2>
          <ul>
            <li>Fatigue and weakness</li>
            <li>Shortness of breath</li>
            <li>Pale or yellowish skin</li>
            <li>Cold hands and feet</li>
            <li>Dizziness or lightheadedness</li>
            <li>Irregular or fast heartbeat (palpitations)</li>
            <li>Chest pain (in severe cases)</li>
            <li>Headaches</li>
          </ul>
        </div>
        <div ref={sections.causesRef} className="Women-menstrual-card">
          <h2>Causes of Anemia</h2>
          <ul>
            <li><strong>Nutritional Deficiencies:</strong> Lack of iron, vitamin B12, or folate.</li>
            <li><strong>Chronic Diseases:</strong> Conditions like kidney disease, cancer, or inflammatory diseases.</li>
            <li><strong>Blood Loss:</strong> Heavy menstruation, ulcers, or internal bleeding.</li>
            <li><strong>Genetic Factors:</strong> Inherited conditions like sickle cell anemia or thalassemia.</li>
            <li><strong>Bone Marrow Disorders:</strong> Diseases like leukemia or aplastic anemia.</li>
          </ul>
        </div>
        <div ref={sections.diagnosisRef} className="Women-menstrual-card">
          <h2>Diagnosis of Anemia</h2>
          <ul>
            <li><strong>Complete Blood Count (CBC):</strong> Measures levels of red blood cells, hemoglobin, and hematocrit.</li>
            <li><strong>Peripheral Blood Smear:</strong> Examines size, shape, and color of red blood cells.</li>
            <li><strong>Iron Studies:</strong> Tests like serum ferritin and transferrin saturation to check iron levels.</li>
            <li><strong>Vitamin B12 and Folate Tests:</strong> Identifies deficiencies causing anemia.</li>
            <li><strong>Bone Marrow Biopsy:</strong> For severe or unexplained anemia.</li>
          </ul>
        </div>
        <div ref={sections.treatmentRef} className="Women-menstrual-card">
          <h2>Treatment of Anemia</h2>
          <ul>
            <li>
              <strong>Iron-Deficiency Anemia:</strong> Iron supplements, dietary changes, and treating underlying causes.
            </li>
            <li>
              <strong>Vitamin-Deficiency Anemia:</strong> Vitamin B12 injections or folate supplements.
            </li>
            <li>
              <strong>Aplastic Anemia:</strong> Bone marrow transplant or immunosuppressive drugs.
            </li>
            <li>
              <strong>Hemolytic Anemia:</strong> Corticosteroids, blood transfusions, and treating infections.
            </li>
            <li>
              <strong>Sickle Cell Anemia:</strong> Hydroxyurea, pain management, blood transfusions, and stem cell transplants.
            </li>
          </ul>
        </div>
        <div ref={sections.complicationsRef} className="Women-menstrual-card">
          <h2>Complications of Untreated Anemia</h2>
          <ul>
            <li>Severe fatigue and decreased productivity</li>
            <li>Heart problems (enlarged heart or heart failure)</li>
            <li>Pregnancy complications (premature birth, low birth weight)</li>
            <li>Developmental delays in children</li>
          </ul>
        </div>
        <div ref={sections.preventionRef} className="Women-menstrual-card">
          <h2>Prevention of Anemia</h2>
          <ul>
            <li><strong>Nutrient-Rich Diet:</strong> Include iron, vitamin B12, and folate-rich foods.</li>
            <li><strong>Iron Supplementation:</strong> Recommended during pregnancy or for individuals at risk.</li>
            <li><strong>Avoid Risk Factors:</strong> Minimize exposure to toxins and infections.</li>
            <li><strong>Screening:</strong> Regular blood tests for those with family history or chronic diseases.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Anemia;
