import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./WomenHealth.css"

const EyeSightTest = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const introductionRef = useRef(null);
  const whatIsTestRef = useRef(null);
  const importanceRef = useRef(null);
  const typesRef = useRef(null);
  const preparationRef = useRef(null);
  const frequencyRef = useRef(null);
  const signsRef = useRef(null);
  const tipsRef = useRef(null);
  const resourcesRef = useRef(null);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="Women-container">
      {/* Back Navigation */}
      <div className="Women-back-arrow" onClick={handleBackClick}>
        ← Eye Care
      </div>

      {/* Sidebar */}
      <div className="Women-sidebar">
        <h3>Table of Contents</h3>
        <ul>
          <li onClick={() => scrollToSection(introductionRef)}>Introduction</li>
          <li onClick={() => scrollToSection(whatIsTestRef)}>
            What is an Eye Sight Test?
          </li>
          <li onClick={() => scrollToSection(importanceRef)}>
            Why Are Eye Sight Tests Important?
          </li>
          <li onClick={() => scrollToSection(typesRef)}>Types of Eye Sight Tests</li>
          <li onClick={() => scrollToSection(preparationRef)}>
            How to Prepare for an Eye Sight Test
          </li>
          <li onClick={() => scrollToSection(frequencyRef)}>
            Frequency of Eye Tests
          </li>
          <li onClick={() => scrollToSection(signsRef)}>
            Signs You Need an Eye Test
          </li>
          <li onClick={() => scrollToSection(tipsRef)}>
            Tips for Maintaining Eye Health
          </li>
          <li onClick={() => scrollToSection(resourcesRef)}>
            Recommended Resources
          </li>
        </ul>
      </div>

      {/* Main Content */}
      <div className="Women-content">
        {/* Introduction */}
        <div ref={introductionRef} className="Women-menstrual-card">
          <h2>Introduction</h2>
          <p>
            Eye sight tests are crucial for maintaining healthy vision and detecting early signs of eye conditions. Whether you're experiencing vision issues or simply want a routine check-up, understanding the process and its benefits can help you take proactive steps toward eye care.
          </p>
        </div>

        {/* What is an Eye Sight Test */}
        <div ref={whatIsTestRef} className="Women-menstrual-card">
          <h3>What is an Eye Sight Test?</h3>
          <p>
            An eye sight test is a series of examinations conducted by an optometrist or ophthalmologist to assess your vision and overall eye health.
          </p>
          <ul>
            <li>Clarity of vision (visual acuity)</li>
            <li>Need for corrective lenses (glasses or contact lenses)</li>
            <li>Presence of any eye diseases or conditions like glaucoma or cataracts</li>
          </ul>
        </div>

        {/* Why Are Eye Sight Tests Important */}
        <div ref={importanceRef} className="Women-menstrual-card">
          <h3>Why Are Eye Sight Tests Important?</h3>
          <ul>
            <li>Detect vision problems early</li>
            <li>Help identify health conditions like diabetes or high blood pressure</li>
            <li>Improve quality of life by correcting vision impairments</li>
            <li>Prevent further deterioration of eye health</li>
          </ul>
        </div>

        {/* Types of Eye Sight Tests */}
        <div ref={typesRef} className="Women-menstrual-card">
          <h3>Types of Eye Sight Tests</h3>
          <h4>3.1. Visual Acuity Test</h4>
          <p>Measures how well you see at different distances. Usually conducted with a Snellen chart (letters of different sizes).</p>
          <h4>3.2. Refraction Test</h4>
          <p>Determines your prescription for glasses or contact lenses. Checks for myopia, hyperopia, astigmatism, or presbyopia.</p>
          <h4>3.3. Color Vision Test</h4>
          <p>Checks for color blindness or difficulty distinguishing colors.</p>
          <h4>3.4. Peripheral Vision Test</h4>
          <p>Evaluates your side vision, often used to detect glaucoma.</p>
          <h4>3.5. Slit-Lamp Examination</h4>
          <p>Inspects the structure of your eyes (cornea, lens, and retina) for abnormalities or damage.</p>
        </div>

        {/* How to Prepare for an Eye Sight Test */}
        <div ref={preparationRef} className="Women-menstrual-card">
          <h3>How to Prepare for an Eye Sight Test</h3>
          <ul>
            <li>List your symptoms: Note any issues like blurry vision, eye strain, or headaches.</li>
            <li>Bring your current prescription: Glasses or contact lenses should be brought along.</li>
            <li>Provide medical history: Share any family history of eye diseases.</li>
            <li>Avoid eye strain: Rest your eyes before the appointment, especially if you use screens often.</li>
          </ul>
        </div>

        {/* Frequency of Eye Tests */}
        <div ref={frequencyRef} className="Women-menstrual-card">
          <h3>Frequency of Eye Tests</h3>
          <ul>
            <li>Children (0–18 years): Every 1–2 years</li>
            <li>Adults (19–40 years): Every 2 years</li>
            <li>Older Adults (41+ years): Annually or as advised by your doctor</li>
          </ul>
        </div>

        {/* Signs You Need an Eye Test */}
        <div ref={signsRef} className="Women-menstrual-card">
          <h3>Signs You Need an Eye Test</h3>
          <ul>
            <li>Blurry or double vision</li>
            <li>Frequent headaches</li>
            <li>Difficulty reading or focusing on objects</li>
            <li>Eye strain, especially after prolonged screen time</li>
          </ul>
        </div>

        {/* Tips for Maintaining Eye Health */}
        <div ref={tipsRef} className="Women-menstrual-card">
          <h3>Tips for Maintaining Eye Health</h3>
          <ul>
            <li>Follow the 20-20-20 rule: Look at something 20 feet away for 20 seconds every 20 minutes.</li>
            <li>Wear sunglasses with UV protection.</li>
            <li>Maintain a balanced diet rich in vitamins A, C, and E.</li>
            <li>Stay hydrated and get regular sleep.</li>
          </ul>
        </div>

        {/* Recommended Resources */}
        <div ref={resourcesRef} className="Women-menstrual-card">
          <h3>Recommended Resources</h3>
          <ul>
            <li>
              <strong>Online Eye Tests:</strong> Tools like Essilor Online Vision Test (Note: These are not replacements for professional exams).
            </li>
            <li>
              <strong>Professional Associations:</strong> American Optometric Association or National Eye Institute.
            </li>
            <li>
              <strong>Books:</strong> "The Eye Book" by Gary H. Cassel for understanding eye care.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EyeSightTest;
