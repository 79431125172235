import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

const ProstateCancer = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const sections = {
    overviewRef: useRef(null),
    symptomsRef: useRef(null),
    causesRef: useRef(null),
    diagnosisRef: useRef(null),
    treatmentRef: useRef(null),
  };

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="Women-container">
      {/* Back Navigation */}
      <div className="Women-back-arrow" onClick={handleBackClick}>
        ← Back
      </div>

      {/* Sidebar */}
      <div className="Women-sidebar">
        <h3>Table of Contents</h3>
        <ul>
          <li onClick={() => scrollToSection(sections.overviewRef)}>Overview</li>
          <li onClick={() => scrollToSection(sections.symptomsRef)}>Symptoms</li>
          <li onClick={() => scrollToSection(sections.causesRef)}>Causes and Risk Factors</li>
          <li onClick={() => scrollToSection(sections.diagnosisRef)}>Diagnosis</li>
          <li onClick={() => scrollToSection(sections.treatmentRef)}>Treatment</li>
        </ul>
      </div>

      {/* Main Content */}
      <div className="Women-content">
        {/* Overview */}
        <div ref={sections.overviewRef} className="Women-menstrual-card">
          <h2>Overview</h2>
          <p>
            Prostate cancer is a type of cancer that develops in the prostate gland, a small gland in men that produces seminal fluid. It is one of the most common cancers in men, particularly in older adults.
          </p>
        </div>

        {/* Symptoms */}
        <div ref={sections.symptomsRef} className="Women-menstrual-card">
          <h3>Symptoms</h3>
          <ul>
            <li>Frequent urination, especially at night</li>
            <li>Weak or interrupted urine flow</li>
            <li>Blood in urine or semen</li>
            <li>Pain or discomfort in the pelvic area</li>
            <li>Erectile dysfunction</li>
            <li>Bone pain (in advanced stages)</li>
          </ul>
        </div>

        {/* Causes and Risk Factors */}
        <div ref={sections.causesRef} className="Women-menstrual-card">
          <h3>Causes and Risk Factors</h3>
          <ul>
            <li><strong>Age:</strong> Risk increases after age 50</li>
            <li><strong>Family History:</strong> Close relatives with prostate cancer</li>
            <li><strong>Race:</strong> More common in African-American men</li>
            <li><strong>Diet:</strong> High-fat diet and low intake of fruits/vegetables</li>
            <li><strong>Genetics:</strong> Inherited mutations in BRCA1 or BRCA2 genes</li>
          </ul>
        </div>

        {/* Diagnosis */}
        <div ref={sections.diagnosisRef} className="Women-menstrual-card">
          <h3>Diagnosis</h3>
          <ul>
            <li><strong>Prostate-Specific Antigen (PSA) Test:</strong> Measures PSA levels in the blood</li>
            <li><strong>Digital Rectal Exam (DRE):</strong> Physical exam of the prostate gland</li>
            <li><strong>Biopsy:</strong> Collecting prostate tissue samples for analysis</li>
            <li><strong>Imaging Tests:</strong> MRI, CT scan, or bone scan for advanced cases</li>
          </ul>
        </div>

        {/* Treatment */}
        <div ref={sections.treatmentRef} className="Women-menstrual-card">
          <h3>Treatment</h3>
          <ul>
            <li><strong>Active Surveillance:</strong> For low-risk cancers</li>
            <li><strong>Surgery:</strong> Prostatectomy to remove the prostate gland</li>
            <li><strong>Radiation Therapy:</strong> External beam or brachytherapy</li>
            <li><strong>Hormone Therapy:</strong> Androgen deprivation therapy (reducing male hormones)</li>
            <li><strong>Chemotherapy:</strong> For advanced stages</li>
            <li><strong>Immunotherapy:</strong> Targeting cancer cells with the immune system</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ProstateCancer;
