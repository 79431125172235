import React, { useState, useEffect } from "react";
import "../styles/HeroSection.css";
import { ReactTyped } from "react-typed";

function HeroSection() {
  const [showFirstText, setShowFirstText] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    datetime: "",
  });
  const [isLoading, setIsLoading] = useState(false); // Loading state

  useEffect(() => {
    // Alternate text every 6 seconds
    const interval = setInterval(() => {
      setShowFirstText((prev) => !prev);
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading spinner

    try {
      // Send form data to the server
      const response = await fetch("https://hsuite-landing-page.onrender.com/api/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert("Your demo request has been submitted. We'll contact you soon!");
        setShowForm(false); // Close the form
        setFormData({ name: "", email: "", phone: "", datetime: "" }); // Reset form
      } else {
        alert("There was an error submitting your request. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting demo request:", error);
      alert("Something went wrong. Please try again later.");
    } finally {
      setIsLoading(false); // Stop loading spinner
    }
  };

  return (
    <section className="hero-section">
      <div className="hero-content">
        <h1 className="hero-heading">
          {showFirstText ? (
            <ReactTyped
              strings={["Transform Healthcare Management<br />with Tailor-Made HIMS Solutions"]}
              typeSpeed={30}
              backSpeed={10}
              loop={false}
            />
          ) : (
            <ReactTyped
              strings={["Empowering Hospitals<br />with Scalable and Customizable Solutions"]}
              typeSpeed={30}
              backSpeed={10}
              loop={false}
            />
          )}
        </h1>
        <p className="hero-subtext">
          Revolutionize clinical and administrative workflows with customizable, secure, and scalable systems designed just for your hospital’s needs.
        </p>
        <div className="hero-buttons">
          <button className="demo-button" onClick={() => setShowForm(!showForm)}>
            Book a Demo
          </button>
          <button
            className="contact-button"
            onClick={() =>
              window.open("https://forms.gle/B3WxmZq3KXEtDH5AA", "_blank")
            }
          >
            Join Our Waiting List
          </button>
        </div>
      </div>

      {/* Demo Form */}
      {showForm && (
        <div className="demo-form">
          {/* Close Button */}
          <button className="close-button" onClick={() => setShowForm(false)}>
            ✕
          </button>
          <form onSubmit={handleFormSubmit}>
            <h3>Book a Demo</h3>
            <label htmlFor="name">Name</label>
            <input
              id="name"
              type="text"
              name="name"
              placeholder="Your Name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              name="email"
              placeholder="Your Email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            <label htmlFor="phone">Phone Number</label>
            <input
              id="phone"
              type="tel"
              name="phone"
              placeholder="Your Phone Number"
              value={formData.phone}
              onChange={handleInputChange}
              required
            />
            <label htmlFor="datetime">Preferred Date & Time</label>
            <input
              id="datetime"
              type="datetime-local"
              name="datetime"
              value={formData.datetime}
              onChange={handleInputChange}
              required
            />
            <button type="submit" disabled={isLoading}>
              {isLoading ? (
                <span className="loading-spinner"></span> // Show spinner
              ) : (
                "Submit"
              )}
            </button>
          </form>
        </div>
      )}
    </section>
  );
}

export default HeroSection;
