import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './KYH.css'; 
import asthma from './icons/asthma.png';
import tb from './icons/tuberculosis.png';
import pneumonia from './icons/pneumonia.png';

const RespiratoryHealth = () => {
  const navigate = useNavigate();  // useNavigate hook to handle navigation

  const handleBackClick = () => {
    navigate(-1);  // This will navigate back to the previous page
  };
    return (
        <div className="kyh-container">
            <div className="back-arrow" onClick={handleBackClick}>
                ← KYH
            </div>
          <h1 className='kyh-heading'>Respiratory Health</h1>
          <div className="kyh-row1">
            <div className="kyh-card">
              <Link to="/kyh/asthma">
              <img src={asthma} alt="asthma" className="women-image" />
                <h3>Asthma</h3>
              </Link>
            </div>
            <div className="kyh-card">
              <Link to="/kyh/pneumonia">
              <img src={pneumonia} alt="pneumonia" className="women-image" />
                <h3>Pnemonia</h3>
              </Link>
            </div>
            <div className="kyh-card">
              <Link to="/kyh/tb">
              <img src={tb} alt="TB" className="women-image" />
                <h3>Tuberculosis</h3>
              </Link>
            </div>
          </div>
        </div>
    );
};

export default RespiratoryHealth;
