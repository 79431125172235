import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

const Menopause = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const introductionRef = useRef(null);
  const perimenopauseRef = useRef(null);
  const menopauseRef = useRef(null);
  const postmenopauseRef = useRef(null);
  const symptomsRef = useRef(null);
  const causesRef = useRef(null);
  const healthRisksRef = useRef(null);
  const treatmentRef = useRef(null);
  const lifestyleRef = useRef(null);
  const emotionalWellBeingRef = useRef(null);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="Women-container">
      {/* Back Navigation */}
      <div className="Women-back-arrow" onClick={handleBackClick}>
        ← Women Health
      </div>

      {/* Sidebar */}
      <div className="Women-sidebar">
        <h3>Table of Contents</h3>
        <ul>
          <li onClick={() => scrollToSection(introductionRef)}>What is Menopause?</li>
          <li onClick={() => scrollToSection(perimenopauseRef)}>Perimenopause</li>
          <li onClick={() => scrollToSection(menopauseRef)}>Menopause</li>
          <li onClick={() => scrollToSection(postmenopauseRef)}>Postmenopause</li>
          <li onClick={() => scrollToSection(symptomsRef)}>Symptoms of Menopause</li>
          <li onClick={() => scrollToSection(causesRef)}>Causes and Risk Factors</li>
          <li onClick={() => scrollToSection(healthRisksRef)}>Health Risks Associated with Menopause</li>
          <li onClick={() => scrollToSection(treatmentRef)}>Treatment Options</li>
          <li onClick={() => scrollToSection(lifestyleRef)}>Lifestyle Adjustments</li>
          <li onClick={() => scrollToSection(emotionalWellBeingRef)}>Emotional Well-Being</li>
        </ul>
      </div>

      {/* Main Content */}
      <div className="Women-content">
        {/* Introduction */}
        <div ref={introductionRef} className="Women-menstrual-card">
          <h2>Menopause</h2>
          <p>
            Menopause is a natural biological process that marks the end of a woman's reproductive years. It is characterized by the cessation of menstruation and significant hormonal changes that can affect both physical and mental health.
          </p>
          <p>
            Understanding menopause is essential for managing its symptoms and maintaining overall health during this phase of life.
          </p>
          <h4>What is Menopause?</h4>
          <p>
            Menopause is defined as the permanent cessation of menstruation, marking the end of a woman’s reproductive period. It typically occurs between the ages of 45 and 55 but can happen earlier or later.
          </p>
          <h5>Key Terms:</h5>
          <ul>
            <li><strong>Perimenopause:</strong> The transition period leading up to menopause with hormonal fluctuations.</li>
            <li><strong>Menopause:</strong> Officially begins after 12 months without a period.</li>
            <li><strong>Postmenopause:</strong> The years following menopause, marked by new health risks like osteoporosis.</li>
          </ul>
        </div>

        {/* Stages of Menopause */}
        <div ref={perimenopauseRef} className="Women-menstrual-card">
          <h3>2. Stages of Menopause</h3>
          <h4>2.1 Perimenopause (Pre-Menopause Transition)</h4>
          <p>
            Perimenopause is the transitional phase before menopause, marked by hormonal changes and symptoms like irregular periods, hot flashes, and mood swings.
          </p>
          <p><strong>Duration:</strong> Typically lasts 4 to 8 years.</p>
        </div>

        <div ref={menopauseRef} className="Women-menstrual-card">
          <h4>2.2 Menopause</h4>
          <p>
            Menopause begins when a woman has gone 12 months without a menstrual period. It is characterized by the end of ovarian function and significant hormonal changes.
          </p>
          <p><strong>Symptoms:</strong> Hot flashes, weight gain, mood changes, and vaginal dryness.</p>
        </div>

        <div ref={postmenopauseRef} className="Women-menstrual-card">
          <h4>2.3 Postmenopause</h4>
          <p>
            Postmenopause refers to the years following menopause, where some symptoms subside, but health risks like osteoporosis and heart disease may increase.
          </p>
        </div>

        {/* Symptoms */}
        <div ref={symptomsRef} className="Women-menstrual-card">
          <h3>3. Symptoms of Menopause</h3>
          <h4>3.1 Physical Symptoms</h4>
          <ul>
            <li><strong>Hot Flashes:</strong> Sudden feelings of warmth, primarily in the face, neck, and chest.</li>
            <li><strong>Night Sweats:</strong> Intense sweating during sleep, disrupting rest.</li>
            <li><strong>Vaginal Dryness:</strong> Thinning and drying of vaginal tissues, leading to discomfort.</li>
            <li><strong>Sleep Disturbances:</strong> Difficulty falling or staying asleep.</li>
            <li><strong>Weight Gain:</strong> Often due to hormonal changes and slower metabolism.</li>
            <li><strong>Hair and Skin Changes:</strong> Thinning hair, increased facial hair, and drier skin.</li>
          </ul>
          <h4>3.2 Emotional and Psychological Symptoms</h4>
          <ul>
            <li><strong>Mood Swings:</strong> Fluctuations in mood, including irritability and anxiety.</li>
            <li><strong>Memory Problems:</strong> "Brain fog" or difficulty concentrating.</li>
            <li><strong>Anxiety and Depression:</strong> Increased feelings of anxiety or sadness due to hormonal changes.</li>
          </ul>
        </div>

        {/* Causes */}
<div ref={causesRef} className="Women-menstrual-card">
  <h3>Causes and Risk Factors</h3>
  <h4>Natural Decline of Reproductive Hormones</h4>
  <p>As women age, the ovaries gradually produce less estrogen and progesterone, leading to the eventual end of ovulation and menstruation.</p>
  <h4>Premature Menopause</h4>
  <p>Premature menopause can be caused by genetic factors, autoimmune diseases, or medical interventions like chemotherapy or oophorectomy.</p>
  <h4>Surgical Menopause</h4>
  <p>Women who undergo surgery to remove their ovaries experience immediate menopause, with more abrupt and severe symptoms due to the sudden drop in hormone levels.</p>
  <h4>Lifestyle and Environmental Factors</h4>
  <ul>
    <li><strong>Smoking:</strong> Women who smoke tend to experience menopause earlier than non-smokers.</li>
    <li><strong>Body Mass Index (BMI):</strong> Women with lower body fat may experience menopause earlier, as body fat produces estrogen.</li>
    <li><strong>Ethnicity:</strong> Research suggests that women of certain ethnic backgrounds may experience menopause at slightly different ages or have varying symptom severity.</li>
  </ul>
</div>

{/* Health Risks */}
<div ref={healthRisksRef} className="Women-menstrual-card">
  <h3>Health Risks Associated with Menopause</h3>
  <h4>Osteoporosis</h4>
  <p>Estrogen plays a critical role in maintaining bone density. After menopause, the loss of estrogen can lead to a rapid decline in bone density, increasing the risk of osteoporosis.</p>
  <h4>Cardiovascular Disease</h4>
  <p>The drop in estrogen after menopause can increase the risk of heart disease, as estrogen helps protect the cardiovascular system.</p>
  <h4>Urinary Incontinence</h4>
  <p>Declining estrogen levels can cause bladder and urethra tissues to lose elasticity, leading to urinary incontinence.</p>
  <h4>Weight Gain</h4>
  <p>Hormonal and metabolic changes can lead to weight gain, particularly around the abdomen. A healthy diet and regular exercise can help manage weight.</p>
</div>

{/* Treatment */}
<div ref={treatmentRef} className="Women-menstrual-card">
  <h3>Treatment Options</h3>
  <h4>Hormone Replacement Therapy (HRT)</h4>
  <ul>
    <li><strong>Estrogen-Only HRT:</strong> Suitable for women who have had a hysterectomy.</li>
    <li><strong>Combined HRT:</strong> Includes estrogen and progesterone for women with a uterus to protect against uterine cancer.</li>
    <li><strong>Benefits:</strong> Relieves hot flashes, night sweats, and vaginal dryness while preventing bone loss.</li>
    <li><strong>Risks:</strong> Long-term use may increase the risk of certain cancers, blood clots, and stroke.</li>
  </ul>
  <h4>Non-Hormonal Treatments</h4>
  <p>Options include antidepressants, gabapentin, and clonidine for hot flashes, as well as local vaginal estrogen for dryness.</p>
</div>

{/* Lifestyle Adjustments */}
<div ref={lifestyleRef} className="Women-menstrual-card">
  <h3>Lifestyle Adjustments for Managing Menopause</h3>
  <h4>Diet and Nutrition</h4>
  <p>Include calcium, vitamin D, and phytoestrogens (soy, flaxseeds) in your diet. Stay hydrated and avoid triggers like caffeine.</p>
  <h4>Exercise</h4>
  <p>Engage in weight-bearing exercises, cardio, and mind-body activities like yoga to maintain health.</p>
  <h4>Stress Management</h4>
  <p>Practice mindfulness, meditation, and breathing exercises to reduce stress and manage symptoms.</p>
</div>

{/* Emotional Well-Being */}
<div ref={emotionalWellBeingRef} className="Women-menstrual-card">
  <h3>Emotional and Psychological Well-Being</h3>
  <h4>Counseling and Therapy</h4>
  <p>Cognitive Behavioral Therapy (CBT) and support groups can help manage mood swings, anxiety, and depression.</p>
  <h4>Relationships and Communication</h4>
  <p>Open communication with partners, family, and healthcare providers is essential for emotional and physical support during menopause.</p>
</div>

      </div>
    </div>
  );
};

export default Menopause;
