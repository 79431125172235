import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

const BreastHealth = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const anatomyRef = useRef(null);
  const conditionsRef = useRef(null);
  const selfExaminationRef = useRef(null);
  const screeningRef = useRef(null);
  const lifestyleRef = useRef(null);
  const treatmentRef = useRef(null);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="Women-container">
      {/* Back Navigation */}
      <div className="Women-back-arrow" onClick={handleBackClick}>
        ← Women Health
      </div>

      {/* Sidebar */}
      <div className="Women-sidebar">
        <h3>Table of Contents</h3>
        <ul>
          <li onClick={() => scrollToSection(anatomyRef)}>Breast Anatomy</li>
          <li onClick={() => scrollToSection(conditionsRef)}>Common Breast Conditions</li>
          <li onClick={() => scrollToSection(selfExaminationRef)}>Self-Examination</li>
          <li onClick={() => scrollToSection(screeningRef)}>Screening Guidelines</li>
          <li onClick={() => scrollToSection(lifestyleRef)}>Lifestyle Factors</li>
          <li onClick={() => scrollToSection(treatmentRef)}>Treatment Options</li>
        </ul>
      </div>

      {/* Main Content */}
      <div className="Women-content">
        {/* Breast Anatomy */}
        <div ref={anatomyRef} className="Women-menstrual-card">
          <h2>Breast Anatomy</h2>
          <p>
            Understanding the structure of your breasts can help you identify any abnormalities and know what changes to look for during self-examinations.
          </p>
          <h4>Key Components:</h4>
          <ul>
            <li><strong>Lobules:</strong> Glands that produce milk during breastfeeding.</li>
            <li><strong>Ducts:</strong> Tubes that transport milk to the nipple.</li>
            <li><strong>Stroma:</strong> Fatty and connective tissue surrounding lobules and ducts.</li>
            <li><strong>Nipple:</strong> The delivery point for milk during breastfeeding.</li>
            <li><strong>Areola:</strong> Dark-pigmented area around the nipple containing glands for lubrication.</li>
          </ul>
        </div>

        {/* Common Breast Conditions */}
        <div ref={conditionsRef} className="Women-menstrual-card">
          <h2>Common Breast Conditions</h2>
          <h4>1. Fibrocystic Breast Changes</h4>
          <p>
            Non-cancerous changes causing lumps and tenderness. Symptoms may worsen during the menstrual cycle.
          </p>
          <h4>2. Breast Cysts</h4>
          <p>
            Fluid-filled sacs that are common and typically benign. They may feel firm and smooth to touch.
          </p>
          <h4>3. Mastitis</h4>
          <p>
            Infection of the breast tissue, often in breastfeeding women. Causes pain, swelling, and flu-like symptoms.
          </p>
          <h4>4. Benign Breast Tumors</h4>
          <p>
            Non-cancerous lumps like fibroadenomas that are usually painless and movable.
          </p>
          <h4>5. Breast Cancer</h4>
          <p>
            Malignant cells forming in breast tissue. Symptoms include lumps, shape changes, nipple discharge, and skin dimpling.
          </p>
        </div>

        {/* Self-Examination */}
        <div ref={selfExaminationRef} className="Women-menstrual-card">
          <h2>Self-Examination</h2>
          <h4>How to Perform:</h4>
          <ul>
            <li><strong>Visual:</strong> Look for changes in size, shape, or skin texture in front of a mirror.</li>
            <li><strong>Manual:</strong> Use your fingers to check for lumps in circular motions while standing or lying down.</li>
          </ul>
          <h4>Frequency:</h4>
          <p>Once a month, a few days after your menstrual period.</p>
          <h4>What to Do if You Find a Lump:</h4>
          <p>Consult a healthcare provider for further evaluation.</p>
        </div>

        {/* Screening Guidelines */}
        <div ref={screeningRef} className="Women-menstrual-card">
          <h2>Screening Guidelines</h2>
          <h4>Clinical Breast Examination:</h4>
          <p>
            Recommended every 1-3 years for women aged 20-39 and annually for women aged 40 and above.
          </p>
          <h4>Mammograms:</h4>
          <p>
            Begin annual mammograms at age 40 for women of average risk. High-risk individuals may require earlier or additional screenings.
          </p>
        </div>

        {/* Lifestyle Factors */}
        <div ref={lifestyleRef} className="Women-menstrual-card">
          <h2>Lifestyle Factors</h2>
          <h4>1. Diet:</h4>
          <p>Eat a balanced diet rich in fruits, vegetables, and lean proteins. Limit alcohol and processed foods.</p>
          <h4>2. Exercise:</h4>
          <p>
            Engage in 150 minutes of moderate exercise weekly to regulate hormones and maintain a healthy weight.
          </p>
          <h4>3. Weight Management:</h4>
          <p>Maintaining a healthy weight reduces the risk of breast cancer.</p>
          <h4>4. Smoking:</h4>
          <p>Quitting smoking improves overall breast health and reduces cancer risks.</p>
        </div>

        {/* Treatment Options */}
        <div ref={treatmentRef} className="Women-menstrual-card">
          <h2>Treatment Options</h2>
          <h4>1. Medication:</h4>
          <p>
            Hormone therapy and antibiotics are common for conditions like breast cancer and mastitis.
          </p>
          <h4>2. Surgery:</h4>
          <p>Options include lumpectomy, mastectomy, and reconstructive procedures.</p>
          <h4>3. Radiation Therapy:</h4>
          <p>Targets and kills cancer cells post-surgery.</p>
          <h4>4. Chemotherapy:</h4>
          <p>Used for aggressive or advanced-stage breast cancer.</p>
        </div>
      </div>
    </div>
  );
};

export default BreastHealth;
