import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as BmiIcon } from './icons/bmi.svg';
import { ReactComponent as HealthIcon } from './icons/HI.svg';
import { ReactComponent as ColorIcon } from './icons/bmi.svg';
import { ReactComponent as EyeSightIcon } from './icons/eye.svg';
import { ReactComponent as CancerIcon } from './icons/cancer.svg';
import { ReactComponent as SkinIcon } from './icons/skin_allergy.svg';
import { ReactComponent as AnaemiaIcon } from './icons/anaemia.svg';
import { ReactComponent as NutritionIcon } from './icons/diet.svg';
import { ReactComponent as FirstAidIcon } from './icons/first_aid.svg';
import { ReactComponent as WomenIcon } from './icons/women.svg';
import { ReactComponent as MentalHealthIcon } from './icons/mental_health_counsellor.svg';
import ChildCareImage from './icons/pediatrics.png';
import BloodImage from './icons/blood.png';
import RespirationImage from './icons/respiration.png';
import BrainImage from './icons/brain.png';


import './KYH.css';

const KYH = () => {
  return (
    <div className="kyh-container">
      <h1 className='kyh-heading'>Know Your Health (KYH)</h1>
      <div className="kyh-row1">
        <div className="kyh-card">
          <Link to="/kyh/bmi-chart-calculator">
            <BmiIcon className="kyh-image" />
            <h3>BMI Calculator</h3>
          </Link>
        </div>
        <div className="kyh-card">
          <Link to="/kyh/health-indicators">
            <HealthIcon className="kyh-image" />
            <h3>Health Indicators</h3>
          </Link>
        </div>
        <div className="kyh-card">
          <Link to="/kyh/women-health">
            <WomenIcon className="kyh-image" />
            <h3>Women's Health</h3>
          </Link>
        </div>
      </div>
      <div className="kyh-row2">
        <div className="kyh-card">
          <Link to="/kyh/eye-care">
            <EyeSightIcon className="kyh-image" />
            <h3>Eye Care</h3>
          </Link>
        </div>
        <div className="kyh-card">
          <Link to="/kyh/cancer">
            <CancerIcon className="kyh-image" />
            <h3>Cancer Symptoms</h3>
          </Link>
        </div>
        <div className="kyh-card">
          <Link to="/kyh/child-care">
            <img src={ChildCareImage} alt="Child Care" className="kyh-image-png" />
            <h3>Child Care</h3>
          </Link>
        </div>
      </div>
      <div className="kyh-row3">
      <div className="kyh-card">
        <Link to="/kyh/blood">
          <img src={BloodImage} alt="Blood" className="kyh-image-png" />
          <h3>Blood</h3>
        </Link>
      </div>
        <div className="kyh-card">
          <Link to="/kyh/nutrition-guide">
            <NutritionIcon className="kyh-image" />
            <h3>Nutrition and Diet</h3>
          </Link>
        </div>
        <div className="kyh-card">
          <Link to="/kyh/first-aid">
            <FirstAidIcon className="kyh-image" />
            <h3>First Aid and Emergency</h3>
          </Link>
        </div>
      </div>
      <div className="kyh-row4">
      <div className="kyh-card">
          <Link to="/kyh/skin-allergy">
            <SkinIcon className="kyh-image" />
            <h3>Skin Allergy</h3>
          </Link>
        </div>
        <div className="kyh-card">
          <Link to="/kyh/mental-assessment">
            <MentalHealthIcon className="kyh-image" />
            <h3>Mental Health Assessment</h3>
          </Link>
        </div>
        <div className="kyh-card">
          <Link to="/kyh/respiratory-health">
            <img src={RespirationImage} alt="Respiratory Health" className="kyh-image-png" />
            <h3>Respiratory Health</h3>
          </Link>
        </div>
      </div>
      <div className="kyh-row5">
      {/* <div className="kyh-card">
          <Link to="/kyh/liver-health">
            <ColorIcon className="kyh-image" />
            <h3>Liver Health</h3>
          </Link>
        </div> */}
          <div className="kyh-card">
            <Link to="/kyh/brain-guide">
              <img src={BrainImage} alt="Brain" className="kyh-image-png" />
              <h3>Brain</h3>
            </Link>
          </div>
        {/* <div className="kyh-card">
          <Link to="/kyh/thyroidism">
            <MentalHealthIcon className="kyh-image" />
            <h3>Thyroidism</h3>
          </Link>
        </div> */}
      </div>
      {/* <div className="kyh-appointment-button">
        <Link to="/doctors">
          <button className="appointment-button">Book an Appointment</button>
        </Link>
      </div> */}
    </div>
  );
};

export default KYH;
