import React from "react";
import "../styles/HealthcareFeatures.css";
import { FiClock, FiSmile, FiTrendingUp, FiWifiOff, FiWifi, FiDollarSign } from "react-icons/fi"; // React Icons for black icons
import abhaIcon from "../assets/abha.jpg"; // Replace with your downloaded icons
import fhirIcon from "../assets/fhir.jpeg";
import hipaaIcon from "../assets/hipaa.png";
import snomedIcon from "../assets/snomed.png";
import awsIcon from "../assets/aws.png";

function HealthcareFeatures() {
  const firstRowFeatures = [
    { icon: <img src={abhaIcon} alt="ABHA Integration" className="feature-icon" />, caption: "ABHA Integration" },
    { icon: <img src={fhirIcon} alt="FHIR Integration" className="feature-icon" />, caption: "FHIR Integration" },
    { icon: <img src={hipaaIcon} alt="HIPAA Compliant" className="feature-icon" />, caption: "HIPAA Compliant" },
    { icon: <img src={snomedIcon} alt="SNOMED CT Integration" className="feature-icon" />, caption: "SNOMED CT Integration" },
    { icon: <img src={awsIcon} alt="AWS Integration" className="feature-icon" />, caption: "AWS Integration" },
  ];

  const secondRowFeatures = [
    { icon: <FiClock size={70} className="react-icon" />, caption: "24/7 Support" },
    { icon: <FiSmile size={70} className="react-icon" />, caption: "Enhanced Patient Experience" },
    { icon: <FiTrendingUp size={70} className="react-icon" />, caption: "Scalability" },
    { icon: <FiWifiOff size={70} className="react-icon" />, caption: "Offline Mode" },
    { icon: <FiWifi size={70} className="react-icon" />, caption: "Online Mode" },
    { icon: <FiDollarSign size={70} className="react-icon" />, caption: "Affordable" },
  ];

  return (
    <section className="healthcare-features">
      <h2 className="heading">Reimagine Healthcare Operations with HSuite</h2>
      <div className="features-container">
        <div className="row">
          {firstRowFeatures.map((feature, index) => (
            <div className="feature-item" key={index}>
              {feature.icon}
              <p className="feature-caption">{feature.caption}</p>
            </div>
          ))}
        </div>
        <div className="row">
          {secondRowFeatures.map((feature, index) => (
            <div className="feature-item" key={index}>
              {feature.icon}
              <p className="feature-caption">{feature.caption}</p>
            </div>
          ))}
        </div>
      </div>
      <p className="footer-text">
        Partner with HSuite for smarter, faster, and better healthcare.
      </p>
    </section>
  );
}

export default HealthcareFeatures;
