import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

const BladderCancer = () => {
  const navigate = useNavigate();

  const sections = {
    overviewRef: useRef(null),
    symptomsRef: useRef(null),
    causesRef: useRef(null),
    diagnosisRef: useRef(null),
    treatmentRef: useRef(null),
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="Women-container">
      <div className="Women-back-arrow" onClick={handleBackClick}>
        ← Back
      </div>
      <div className="Women-sidebar">
        <h3>Table of Contents</h3>
        <ul>
          <li onClick={() => scrollToSection(sections.overviewRef)}>Overview</li>
          <li onClick={() => scrollToSection(sections.symptomsRef)}>Symptoms</li>
          <li onClick={() => scrollToSection(sections.causesRef)}>Causes and Risk Factors</li>
          <li onClick={() => scrollToSection(sections.diagnosisRef)}>Diagnosis</li>
          <li onClick={() => scrollToSection(sections.treatmentRef)}>Treatment</li>
        </ul>
      </div>
      <div className="Women-content">
        <div ref={sections.overviewRef} className="Women-menstrual-card">
          <h2>Overview</h2>
          <p>
            Bladder cancer starts in the cells of the bladder lining. The most common type is urothelial carcinoma, but squamous cell carcinoma and adenocarcinoma also occur. It is often diagnosed early, making treatment more effective.
          </p>
        </div>
        <div ref={sections.symptomsRef} className="Women-menstrual-card">
          <h3>Symptoms</h3>
          <ul>
            <li>Blood in urine (hematuria), appearing red or cola-colored</li>
            <li>Frequent urination</li>
            <li>Pain or burning sensation during urination</li>
            <li>Pelvic or lower back pain (advanced cases)</li>
            <li>Urinary urgency without passing much urine</li>
          </ul>
        </div>
        <div ref={sections.causesRef} className="Women-menstrual-card">
          <h3>Causes and Risk Factors</h3>
          <ul>
            <li>Smoking: Leading cause of bladder cancer due to exposure to harmful chemicals.</li>
            <li>Chemical exposure: Industrial chemicals like arsenic or dyes used in rubber and textile industries.</li>
            <li>Chronic bladder irritation: Repeated urinary infections or prolonged catheter use.</li>
            <li>Age and gender: More common in older adults and men.</li>
            <li>Family history: Genetic predisposition to bladder cancer.</li>
          </ul>
        </div>
        <div ref={sections.diagnosisRef} className="Women-menstrual-card">
          <h3>Diagnosis</h3>
          <ul>
            <li>Urine tests: Checking for cancer cells (cytology) or tumor markers.</li>
            <li>Cystoscopy: Viewing the bladder with a camera inserted through the urethra.</li>
            <li>Imaging tests: CT urogram or MRI to locate tumors.</li>
            <li>Biopsy: Collecting bladder tissue for analysis.</li>
          </ul>
        </div>
        <div ref={sections.treatmentRef} className="Women-menstrual-card">
          <h3>Treatment</h3>
          <ul>
            <li>Surgery: Transurethral resection (TURBT) for early-stage cancer or radical cystectomy for advanced cases.</li>
            <li>Intravesical therapy: Delivering drugs like BCG directly into the bladder.</li>
            <li>Chemotherapy: Systemic or localized to the bladder.</li>
            <li>Immunotherapy: Immune checkpoint inhibitors like pembrolizumab.</li>
            <li>Radiation therapy: Used for advanced or inoperable cases.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BladderCancer;
