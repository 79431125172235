import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

const AsthmaGuide = () => {
  const navigate = useNavigate();

  const sections = {
    howItAffectsRef: useRef(null),
    typesRef: useRef(null),
    triggersRef: useRef(null),
    symptomsRef: useRef(null),
    diagnosisRef: useRef(null),
    treatmentRef: useRef(null),
    preventionRef: useRef(null),
    complicationsRef: useRef(null),
    emergenciesRef: useRef(null),
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="Women-container">
      <div className="Women-back-arrow" onClick={handleBackClick}>
        ← Back
      </div>
      <div className="Women-sidebar">
        <h3>Table of Contents</h3>
        <ul>
          <li onClick={() => scrollToSection(sections.howItAffectsRef)}>How Asthma Affects the Airways</li>
          <li onClick={() => scrollToSection(sections.typesRef)}>Types of Asthma</li>
          <li onClick={() => scrollToSection(sections.triggersRef)}>Causes and Triggers</li>
          <li onClick={() => scrollToSection(sections.symptomsRef)}>Symptoms of Asthma</li>
          <li onClick={() => scrollToSection(sections.diagnosisRef)}>Diagnosis of Asthma</li>
          <li onClick={() => scrollToSection(sections.treatmentRef)}>Treatment and Management</li>
          <li onClick={() => scrollToSection(sections.preventionRef)}>Prevention and Lifestyle Tips</li>
          <li onClick={() => scrollToSection(sections.complicationsRef)}>Complications of Asthma</li>
          <li onClick={() => scrollToSection(sections.emergenciesRef)}>Emergency Situations</li>
        </ul>
      </div>
      <div className="Women-content">
        <div ref={sections.howItAffectsRef} className="Women-menstrual-card">
          <h2>How Asthma Affects the Airways</h2>
          <p>
            In asthma, the airways react strongly to certain triggers, causing:
          </p>
          <ul>
            <li><strong>Inflammation:</strong> The airways become swollen, reducing airflow.</li>
            <li><strong>Bronchoconstriction:</strong> The muscles around the airways tighten, narrowing them.</li>
            <li><strong>Increased Mucus Production:</strong> Excess mucus further blocks airflow.</li>
          </ul>
        </div>

        <div ref={sections.typesRef} className="Women-menstrual-card">
          <h2>Types of Asthma</h2>
          <ul>
            <li><strong>Allergic Asthma:</strong> Triggered by allergens like pollen, dust mites, or pet dander.</li>
            <li><strong>Non-Allergic Asthma:</strong> Triggered by factors like stress, exercise, or respiratory infections.</li>
            <li><strong>Exercise-Induced Bronchoconstriction (EIB):</strong> Symptoms occur during or after physical activity.</li>
            <li><strong>Occupational Asthma:</strong> Caused by exposure to irritants in the workplace.</li>
            <li><strong>Childhood Asthma:</strong> Often linked to respiratory infections or allergens.</li>
            <li><strong>Adult-Onset Asthma:</strong> Develops later in life and may be more persistent.</li>
          </ul>
        </div>

        <div ref={sections.triggersRef} className="Women-menstrual-card">
          <h2>Causes and Triggers</h2>
          <p>Asthma has no single cause but is influenced by genetic and environmental factors.</p>
          <ul>
            <li><strong>Allergens:</strong> Pollen, mold, dust mites, pet dander.</li>
            <li><strong>Irritants:</strong> Smoke, strong odors, air pollution, chemicals.</li>
            <li><strong>Respiratory Infections:</strong> Colds, flu, sinus infections.</li>
            <li><strong>Weather Conditions:</strong> Extreme cold, heat, or high humidity.</li>
            <li><strong>Emotions:</strong> Stress or anxiety.</li>
          </ul>
        </div>

        <div ref={sections.symptomsRef} className="Women-menstrual-card">
          <h2>Symptoms of Asthma</h2>
          <ul>
            <li>Shortness of breath.</li>
            <li>Wheezing (a whistling sound while breathing).</li>
            <li>Tightness or pain in the chest.</li>
            <li>Persistent coughing.</li>
          </ul>
          <h3>Warning Signs of Severe Asthma Attack</h3>
          <ul>
            <li>Severe breathlessness even at rest.</li>
            <li>Rapid worsening of symptoms.</li>
            <li>Bluish tint to lips or face.</li>
          </ul>
        </div>

        <div ref={sections.diagnosisRef} className="Women-menstrual-card">
          <h2>Diagnosis of Asthma</h2>
          <ul>
            <li><strong>Medical History:</strong> Discussion of symptoms and triggers.</li>
            <li><strong>Physical Examination:</strong> Listening for wheezing or abnormalities.</li>
            <li><strong>Pulmonary Function Tests:</strong> Spirometry and peak flow tests.</li>
          </ul>
        </div>

        <div ref={sections.treatmentRef} className="Women-menstrual-card">
          <h2>Treatment and Management</h2>
          <ul>
            <li><strong>Reliever Inhalers:</strong> Quick relief during attacks (e.g., Albuterol).</li>
            <li><strong>Controller Medications:</strong> Prevent symptoms (e.g., corticosteroids).</li>
            <li><strong>Biologic Therapies:</strong> For severe cases (e.g., Omalizumab).</li>
            <li><strong>Asthma Action Plan:</strong> A personalized plan for managing asthma.</li>
          </ul>
        </div>

        <div ref={sections.preventionRef} className="Women-menstrual-card">
          <h2>Prevention and Lifestyle Tips</h2>
          <ul>
            <li>Avoid triggers like allergens and irritants.</li>
            <li>Maintain a healthy diet with anti-inflammatory foods.</li>
            <li>Practice stress management techniques like yoga or meditation.</li>
          </ul>
        </div>

        <div ref={sections.complicationsRef} className="Women-menstrual-card">
          <h2>Complications of Asthma</h2>
          <ul>
            <li>Frequent hospitalizations due to severe attacks.</li>
            <li>Permanent airway remodeling and reduced lung function.</li>
            <li>Psychological impact, including anxiety or depression.</li>
          </ul>
        </div>

        <div ref={sections.emergenciesRef} className="Women-menstrual-card">
          <h2>Emergency Situations</h2>
          <ul>
            <li>Follow the Asthma Action Plan and use a rescue inhaler.</li>
            <li>Call emergency services if symptoms do not improve.</li>
            <li>Stay calm and sit upright.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AsthmaGuide;
