import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

const StomachCancer = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const sections = {
    overviewRef: useRef(null),
    symptomsRef: useRef(null),
    causesRef: useRef(null),
    diagnosisRef: useRef(null),
    treatmentRef: useRef(null),
  };

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="Women-container">
      {/* Back Navigation */}
      <div className="Women-back-arrow" onClick={handleBackClick}>
        ← Back
      </div>

      {/* Sidebar */}
      <div className="Women-sidebar">
        <h3>Table of Contents</h3>
        <ul>
          <li onClick={() => scrollToSection(sections.overviewRef)}>Overview</li>
          <li onClick={() => scrollToSection(sections.symptomsRef)}>Symptoms</li>
          <li onClick={() => scrollToSection(sections.causesRef)}>Causes and Risk Factors</li>
          <li onClick={() => scrollToSection(sections.diagnosisRef)}>Diagnosis</li>
          <li onClick={() => scrollToSection(sections.treatmentRef)}>Treatment</li>
        </ul>
      </div>

      {/* Main Content */}
      <div className="Women-content">
        {/* Overview */}
        <div ref={sections.overviewRef} className="Women-menstrual-card">
          <h2>Overview</h2>
          <p>
            Stomach cancer, or gastric cancer, begins in the lining of the stomach. It is more common in men and older adults and often linked to diet, lifestyle, or infections like Helicobacter pylori (H. pylori).
          </p>
        </div>

        {/* Symptoms */}
        <div ref={sections.symptomsRef} className="Women-menstrual-card">
          <h3>Symptoms</h3>
          <ul>
            <li>Persistent indigestion or heartburn</li>
            <li>Loss of appetite and unintentional weight loss</li>
            <li>Nausea or vomiting, sometimes with blood</li>
            <li>Stomach pain or discomfort</li>
            <li>Feeling bloated after eating small amounts</li>
            <li>Fatigue and weakness</li>
            <li>Black, tarry stools (indicating bleeding)</li>
          </ul>
        </div>

        {/* Causes and Risk Factors */}
        <div ref={sections.causesRef} className="Women-menstrual-card">
          <h3>Causes and Risk Factors</h3>
          <ul>
            <li><strong>Infection:</strong> Chronic infection with H. pylori bacteria</li>
            <li><strong>Diet:</strong> High intake of salty, smoked, or pickled foods</li>
            <li><strong>Tobacco and Alcohol:</strong> Increased risk with regular use</li>
            <li><strong>Family History:</strong> Genetic predisposition to gastric cancer</li>
            <li><strong>Chronic Gastritis:</strong> Inflammation of the stomach lining</li>
            <li><strong>Age and Gender:</strong> More common in men and those over 50</li>
          </ul>
        </div>

        {/* Diagnosis */}
        <div ref={sections.diagnosisRef} className="Women-menstrual-card">
          <h3>Diagnosis</h3>
          <ul>
            <li><strong>Endoscopy:</strong> Viewing the stomach lining with a camera</li>
            <li><strong>Biopsy:</strong> Collecting stomach tissue for analysis</li>
            <li><strong>Barium Swallow Test:</strong> Imaging the digestive tract</li>
            <li><strong>CT Scans:</strong> To check for spread (metastasis)</li>
          </ul>
        </div>

        {/* Treatment */}
        <div ref={sections.treatmentRef} className="Women-menstrual-card">
          <h3>Treatment</h3>
          <ul>
            <li><strong>Surgery:</strong> Removing part (partial gastrectomy) or all (total gastrectomy) of the stomach</li>
            <li><strong>Chemotherapy:</strong> Shrinks tumors and kills cancer cells</li>
            <li><strong>Radiation Therapy:</strong> Used alongside other treatments</li>
            <li><strong>Targeted Therapy:</strong> Drugs targeting HER2-positive cancers</li>
            <li><strong>Immunotherapy:</strong> Emerging treatments for advanced cases</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default StomachCancer;
