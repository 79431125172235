import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import WhyHSuite from "./components/WhyHSuite";
import BuiltForYourHospital from "./components/BuiltForYourHospital";
import HealthcareFeatures from "./components/HealthcareFeatures";
import FAQ from "./components/FAQ";
import ContactUsAndTimeline from "./components/ContactUsAndTimeline";
import Footer from "./components/Footer";
import Products from "./pages/Products";
import BlogPage from "./pages/BlogPage";
import KYH from "./pages/kyh/KYH";
import BMIChartCalculator from "./pages/kyh/BMIChartCalculator";
import HealthIndicators from "./pages/kyh/HealthIndicators";
import WomenHealth from "./pages/kyh/WomenHealth";
import MenstrualHealth from "./pages/kyh/MenstrualHealth";
import Pregnancy from "./pages/kyh/Pregnancy";
import Menopause from "./pages/kyh/Menopause";
import BreastHealth from "./pages/kyh/BreastHealth";
import EyeCare from "./pages/kyh/EyeCare";
import CancerSymptoms from "./pages/kyh/CancerSymptoms";
import ChildCare from "./pages/kyh/ChildCare";
import Blood from "./pages/kyh/Blood";
import Nutrition from "./pages/kyh/Nutrition";
import RespiratoryHealth from "./pages/kyh/RespiratoryHealth";
import LiverHealth from "./pages/kyh/Liver";
import MentalHealth from "./pages/kyh/MentalHealth";
import Thyroidism from "./pages/kyh/Thyroidism";
import EyeSightTest from "./pages/kyh/EyeSightTest";
import EyeColorTest from "./pages/kyh/EyeColorTest";
import BreastCancer from "./pages/kyh/BreastCancer";
import LungCancer from "./pages/kyh/LungCancer";
import ColorectalCancer from "./pages/kyh/ColorectalCancer";
import ProstateCancer from "./pages/kyh/ProstateCancer";
import SkinCancer from "./pages/kyh/SkinCancer";
import StomachCancer from "./pages/kyh/StomachCancer";
import LiverCancer from "./pages/kyh/LiverCancer";
import PancreaticCancer from "./pages/kyh/PancreaticCancer";
import BladderCancer from "./pages/kyh/BladderCancer";
import ChildVaccination from "./pages/kyh/ChildVaccination";
import Anemia from "./pages/kyh/Anemia";
import BloodDonorCompatibility from "./pages/kyh/BloodDonorCompatibility";
import BloodTestGuide from "./pages/kyh/BloodTestGuide";
import NutritionGuide from "./pages/kyh/NutritionGuide";
import FirstAidGuide from "./pages/kyh/FirstAidGuide";
import SkinAllergiesGuide from "./pages/kyh/SkinAllergiesGuide";
import BrainGuide from "./pages/kyh/BrainGuide";
import AsthmaGuide from "./pages/kyh/AsthmaGuide";
import PneumoniaOverview from "./pages/kyh/PneumoniaOverview";
import TuberculosisOverview from "./pages/kyh/TuberculosisOverview";
import MentalHealthAssessment from "./pages/kyh/MentalHealthAssessment";
import BloodBanks from "./pages/kyh/BloodBanks";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        {/* Home Page */}
        <Route
          path="/"
          element={
            <>
              <HeroSection />
              <WhyHSuite />
              <BuiltForYourHospital />
              <HealthcareFeatures />
              <FAQ />
              <ContactUsAndTimeline id="contact-us-section" />

              <Footer />
            </>
          }
        />

        {/* Products Page */}
        <Route path="/products" element={
          <>
            <Products />
            <Footer />
          </>
          } />

        {/* Blog Page */}
        <Route path="/blogs" element={
          <>
            <BlogPage />
            <Footer />
          </>
          } />

        {/* KYH Main Page */}
        <Route path="/kyh" element={
          <>
            <KYH />
            <Footer />
          </>} />

        {/* KYH Subpages */}
        <Route path="/kyh/bmi-chart-calculator" element={<BMIChartCalculator />} />
        <Route path="/kyh/health-indicators" element={<HealthIndicators />} />
        <Route path="/kyh/women-health" element={<WomenHealth />} />
        <Route path="/kyh/menstrual-health" element={<MenstrualHealth />} />
        <Route path="/kyh/pregnancy" element={<Pregnancy />} />
        <Route path="/kyh/menopause" element={<Menopause />} />
        <Route path="/kyh/breast-health" element={<BreastHealth />} />
        <Route path="/kyh/eye-care" element={<EyeCare />} />
        <Route path="/kyh/cancer" element={<CancerSymptoms />} />
        <Route path="/kyh/child-care" element={<ChildCare />} />
        <Route path="/kyh/blood" element={<Blood />} />
        <Route path="/kyh/nutrition" element={<Nutrition />} />
        <Route path="/kyh/respiratory-health" element={<RespiratoryHealth />} />
        <Route path="/kyh/liver-health" element={<LiverHealth />} />
        <Route path="/kyh/mental-health" element={<MentalHealth />} />
        <Route path="/kyh/thyroidism" element={<Thyroidism />} />
        <Route path="kyh/sight-test" element={<EyeSightTest />} />
        <Route path="/kyh/color-test" element={<EyeColorTest />} />
        <Route path="/kyh/breast-cancer" element={<BreastCancer />} />
        <Route path="/kyh/lung-cancer" element={<LungCancer />} />
        <Route path="/kyh/colorectal-cancer" element={<ColorectalCancer />} />
        <Route path="/kyh/prostate-cancer" element={<ProstateCancer />} />
        <Route path="/kyh/skin-cancer" element={<SkinCancer />} />
        <Route path="/kyh/stomach-cancer" element={<StomachCancer />} />
        <Route path="/kyh/liver-cancer" element={<LiverCancer />} />
        <Route path="/kyh/pancreatic-cancer" element={<PancreaticCancer />} />
        <Route path="/kyh/bladder-cancer" element={<BladderCancer />} />
        <Route path="/kyh/child-vaccination" element={<ChildVaccination />} />
        <Route path="/kyh/anemia" element={<Anemia />} />
        <Route path="/kyh/blood-donor" element={<BloodDonorCompatibility />} />
        <Route path="/kyh/blood-type" element={<BloodTestGuide />} />
        <Route path="/kyh/nutrition-guide" element={<NutritionGuide />} />
        <Route path="/kyh/first-aid" element={<FirstAidGuide />} />
        <Route path="/kyh/skin-allergy" element={<SkinAllergiesGuide />} />
        <Route path="/kyh/brain-guide" element={<BrainGuide />} />
        <Route path="/kyh/asthma" element={<AsthmaGuide />} />
        <Route path="/kyh/pneumonia" element={<PneumoniaOverview />} />
        <Route path="/kyh/tb" element={<TuberculosisOverview />} />
        <Route path="/kyh/mental-assessment" element={<MentalHealthAssessment />} />
        <Route path="/kyh/blood-banks" element={<BloodBanks />} />


        <Route path="contact-us" element={
          <>
            <ContactUsAndTimeline />
            <Footer />
          </>} />

      </Routes>
    </Router>
  );
}

export default App;
