import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

const SkinAllergiesGuide = () => {
  const navigate = useNavigate();

  const sections = {
    whatIsSkinAllergyRef: useRef(null),
    causesRef: useRef(null),
    typesRef: useRef(null),
    symptomsRef: useRef(null),
    diagnosisRef: useRef(null),
    treatmentRef: useRef(null),
    preventionRef: useRef(null),
    doctorVisitRef: useRef(null),
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="Women-container">
      <div className="Women-back-arrow" onClick={handleBackClick}>
        ← Back
      </div>
      <div className="Women-sidebar">
        <h3>Table of Contents</h3>
        <ul>
          <li onClick={() => scrollToSection(sections.whatIsSkinAllergyRef)}>
            What is a Skin Allergy?
          </li>
          <li onClick={() => scrollToSection(sections.causesRef)}>
            Common Causes
          </li>
          <li onClick={() => scrollToSection(sections.typesRef)}>
            Types of Skin Allergies
          </li>
          <li onClick={() => scrollToSection(sections.symptomsRef)}>
            Symptoms
          </li>
          <li onClick={() => scrollToSection(sections.diagnosisRef)}>
            Diagnosis
          </li>
          <li onClick={() => scrollToSection(sections.treatmentRef)}>
            Treatment
          </li>
          <li onClick={() => scrollToSection(sections.preventionRef)}>
            Prevention
          </li>
          <li onClick={() => scrollToSection(sections.doctorVisitRef)}>
            When to See a Doctor
          </li>
        </ul>
      </div>
      <div className="Women-content">
        <div ref={sections.whatIsSkinAllergyRef} className="Women-menstrual-card">
          <h2>What is a Skin Allergy?</h2>
          <p>
            A skin allergy is an inflammatory response of the skin caused by
            direct contact with an allergen or by the immune system reacting to
            an allergen. It can range from mild irritation to severe rashes and
            discomfort.
          </p>
        </div>

        <div ref={sections.causesRef} className="Women-menstrual-card">
          <h2>Common Causes of Skin Allergies</h2>
          <ul>
            <li><strong>Environmental Allergens:</strong> Pollen, dust mites, mold, and pet dander.</li>
            <li><strong>Food Allergies:</strong> Nuts, shellfish, milk, eggs, or wheat.</li>
            <li><strong>Contact Allergens:</strong> Nickel, latex, cosmetics, detergents, or dyes.</li>
            <li><strong>Insect Bites or Stings:</strong> Bees, wasps, ants, or mosquitoes.</li>
            <li><strong>Medications:</strong> Antibiotics, aspirin, or other medications.</li>
            <li><strong>Other Triggers:</strong> Sun exposure, heat, sweat, or certain fabrics like wool.</li>
          </ul>
        </div>

        <div ref={sections.typesRef} className="Women-menstrual-card">
          <h2>Types of Skin Allergies</h2>
          <ul>
            <li><strong>Contact Dermatitis:</strong> Redness, itching, and swelling caused by allergens like nickel or cosmetics.</li>
            <li><strong>Eczema (Atopic Dermatitis):</strong> Chronic dry and itchy skin often linked to other allergies.</li>
            <li><strong>Urticaria (Hives):</strong> Raised, itchy welts caused by foods, medications, or insect stings.</li>
            <li><strong>Angioedema:</strong> Swelling of the skin's deeper layers, often around the eyes, lips, or throat.</li>
            <li><strong>Photosensitivity:</strong> Allergic reaction to sunlight or UV rays, leading to redness and itching.</li>
          </ul>
        </div>

        <div ref={sections.symptomsRef} className="Women-menstrual-card">
          <h2>Symptoms of Skin Allergies</h2>
          <ul>
            <li>Redness or rash</li>
            <li>Itching or burning sensation</li>
            <li>Dry or scaly patches</li>
            <li>Blisters or bumps</li>
            <li>Swelling of affected areas</li>
            <li>Flaky or cracked skin</li>
            <li>Oozing or crusting lesions (in severe cases)</li>
          </ul>
        </div>

        <div ref={sections.diagnosisRef} className="Women-menstrual-card">
          <h2>Diagnosis of Skin Allergies</h2>
          <ul>
            <li><strong>Medical History:</strong> Discussion of symptoms, triggers, and family history of allergies.</li>
            <li><strong>Physical Examination:</strong> Examination of the affected area for patterns and severity.</li>
            <li><strong>Allergy Testing:</strong> Patch tests, skin prick tests, or blood tests to identify specific allergens.</li>
          </ul>
        </div>

        <div ref={sections.treatmentRef} className="Women-menstrual-card">
          <h2>Treatment for Skin Allergies</h2>
          <ul>
            <li><strong>Medications:</strong> Antihistamines, topical corticosteroids, or oral medications for severe cases.</li>
            <li><strong>Home Remedies:</strong> Cool compresses, oatmeal baths, and aloe vera to soothe symptoms.</li>
            <li><strong>Lifestyle Changes:</strong> Use hypoallergenic products, wear breathable fabrics, and avoid triggers.</li>
          </ul>
        </div>

        <div ref={sections.preventionRef} className="Women-menstrual-card">
          <h2>Prevention of Skin Allergies</h2>
          <ul>
            <li>Identify and avoid allergens by keeping a record of triggers.</li>
            <li>Use protective measures like gloves when handling irritants.</li>
            <li>Adopt a gentle skincare routine with fragrance-free products.</li>
            <li>Practice stress management techniques to prevent flare-ups.</li>
            <li>Apply sunscreen to protect against UV-induced reactions.</li>
          </ul>
        </div>

        <div ref={sections.doctorVisitRef} className="Women-menstrual-card">
          <h2>When to See a Doctor</h2>
          <ul>
            <li>Symptoms persist or worsen despite home care.</li>
            <li>Severe reactions like swelling of the face or difficulty breathing.</li>
            <li>Signs of infection, such as pus, fever, or extreme pain.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SkinAllergiesGuide;
